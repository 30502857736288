<app-header [cliente]="cliente" [home]="true" [logado]="true"></app-header>
<div class="container-fluid container-principal container-favoritos">
    <div class="row">
        <a class="link-retorno " (click)="voltarHome()">&lt;	voltar</a>
    </div>
    <div class="row">
        <h2>Selecione seu motorista prefencial para este serviço</h2>
    </div>
    <div class="row mt-5">
        <div *ngFor="let favorito of favoritos; let i = index" class="bloco-enderecos col-12">
            <div class="col-md-1">
                <img class="img100" *ngIf="motorista_selecionado == favorito.entregador_id" src="../../../assets/oval_off.png">
                <img class="img100" *ngIf="motorista_selecionado != favorito.entregador_id" (click)="seleciona(favorito.entregador_id)" src="../../../assets/circle_off.svg">
            </div>
            <div class="col-md-6 box-enderecos">
                <div class="box-avatar col-2">
                    <div *ngIf="favorito.avatar" class="avatar_card" [style.background]="'url('+ favorito.avatar + ')'"></div>
                    <div *ngIf="!favorito.avatar" [style.background]="'url(assets/user.png)'" class="avatar_card" ></div>
                    <!-- <img class="btn-fechar" class="avatar-motorista" src="{{favorito.avatar}}" alt=""> -->
                </div>
                <div class="col-10" >
                    <div class="col-md-12">
                        <p>{{favorito.nome}}</p>
                        <p>{{favorito.horario}}</p>
                    </div>
                    <div class="col-md-4 m-0">
                        <bar-rating [rate]="favorito.media" [theme]="'stars'" [max]="5" disabled></bar-rating>
                    </div>
                </div>
                <div class="div-transparente"></div>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-3">
                <app-botao-vermelho  (click)="solicitar()" title="Solicitar"></app-botao-vermelho>
            </div>
        </div> 
    </div>
</div>
<app-footer-logado></app-footer-logado>