import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-botao-vermelho',
  templateUrl: './botao-vermelho.component.html',
  styleUrls: ['./botao-vermelho.component.css']
})
export class BotaoVermelhoComponent implements OnInit {

  @Input() title : String = ''
  constructor() { }

  ngOnInit(): void {
  }

}
