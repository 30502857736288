<app-header [cliente]="cliente" [logado]="true"></app-header>
<div class="container-perfil">
    
<form #form="ngForm" (ngSubmit)="editar(form)">

    <div class="row ">
        <div class="col-md-12 bloco-avatar-perfil mt-5 pt-5">
            <!-- <img *ngIf="cliente.avatar" (click)="abrirArquivo()" class="padrao_img mr-3" src="{{cliente.avatar}}"/>
            <img *ngIf="!cliente.avatar" (click)="abrirArquivo()" class="imgavatar mr-3"  src="assets/usuario_default.png"/> -->
            <div *ngIf="cliente.avatar" class="avatar-padrao avatar-perfil" (click)="abrirArquivo()"  [style.background]="'url('+ cliente.avatar + ')'"></div>
            <div *ngIf="!cliente.avatar" class="avatar-padrao avatar-perfil" [style.background]="'url('+'assets/usuario_default.png'+')'" ></div>
            <input type="file" style="display:none;" name="cliente[avatar]" (click)="abrirArquivo()" [(ngModel)]="cliente.avatar" id="imagem" (change)="imageSelectedAvatar($event.target.files)" class="form-control hide " >
            <span class="ml-3">Faça upload da imagem</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 pt-4 ">
            <p><span>Dados pessoais</span></p>

        </div>
    </div>
    <div class="row mt-3 ">
        <div class="col-md-4 col-lg-3">
            <label>Razão Social</label>
            <input class="form-control" name="cliente[razao_social]" [(ngModel)]="cliente.razao_social" placeholder="Digite aqui" />
        </div>
        <div class="col-md-4 col-lg-3">
            <label>Nome Fantasia</label>
            <input class="form-control" name="cliente[nome_fantasia]" [(ngModel)]="cliente.nome_fantasia" placeholder="Digite aqui" />
        </div>
        <div class="col-md-4 col-lg-3">
            <label>CNPJ</label>
            <input class="form-control" name="cliente[cnpj]"  [(ngModel)]="cliente.cnpj" placeholder="Digite aqui" />
        </div>
        <div class="col-md-4 col-lg-3">
            <label>Celular</label>
            <input class="form-control" name="cliente[celular]" [(ngModel)]="cliente.celular" placeholder="Digite aqui" />
        </div>
        
    </div>
    <div class="row ">
        <div class="col-md-12  mt-3 pt-4 ">
            <span>Endereço</span>
        </div>
        <div class="col-md-3 col-lg-3 pt-4">
            <label>CEP</label>
            <input name="cliente[cep]" class="form-control"  (blur)="cep()" mask="00000-000" [(ngModel)]="cliente.cep" placeholder="Digite aqui" />
        </div>
        <div class="col-md-3 col-lg-3 pt-4">

            <label>Endereço</label>
            <input class="form-control" name="cliente[endereco]" [(ngModel)]="cliente.endereco" placeholder="Digite aqui" />
        </div>
        <div class="col-md-3 col-lg-3 pt-4">

            <label>Número</label>
            <input class="form-control" name="cliente[numero]" [(ngModel)]="cliente.numero" placeholder="Digite aqui" />
        </div>
        <div class="col-md-3 col-lg-3 pt-4">

            <label>Complemento</label>
            <input class="form-control"   name="cliente[complemento]" [(ngModel)]="cliente.complemento" placeholder="Digite aqui" />
        </div>
        <div class="col-md-3 col-lg-3 pt-4">

            <label>Bairro</label>
            <input class="form-control" name="cliente[bairro]" [(ngModel)]="cliente.bairro" placeholder="Digite aqui" />
        </div>
        <div class="col-md-3 col-lg-3 pt-4">

            <label>Cidade</label>
            <input class="form-control" name="cliente[cidade]" [(ngModel)]="cliente.cidade" placeholder="Digite aqui" />
        </div>
        <div class="col-md-3 col-lg-3 pt-4">

            <label>Estado</label>
            <select class="form-control" placeholder="Selecione um Estado"  name="cliente[estado]" [(ngModel)]="cliente.estado" >
                <option value="{{estado}}" *ngFor="let estado of estados"> {{estado}}</option>
            </select>        </div>
    </div>
    <div class="row  mr-4">
        <div class="col-md-12  mt-3 pt-4 ">
            <p><span>Dados de Acesso</span></p>

        </div>
        <div class="col-md-3 col-lg-3 pt-4">

            <label>E-mail</label>
            <input class="form-control" (blur)='PesquisarEmail();' name="cliente[email]" [(ngModel)]="cliente.email" placeholder="Digite aqui" />
            <img *ngIf="!emailValido" class="icone_check" src="/assets/check.png"> 
            <img *ngIf="emailValido" class="icone_check" src="/assets/check2.png">
        </div>
        <div class="col-md-3 col-lg-3 pt-4">

            <label>Senha</label>
            <input class="form-control" type="password" name="cliente[senha]" [(ngModel)]="cliente.senha" placeholder="Digite aqui" />
        </div>
    </div>
    <div class="row mt-4 mb-4">
        <div class="col-md-12">
            <div class="centralizar">
               <button class="submit" type="submit"><app-botao-vermelho  title="Salvar"></app-botao-vermelho></button>

            </div>
        </div>
    </div> 
</form>
</div>
<app-footer-logado></app-footer-logado>