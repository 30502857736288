import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mensagem-erro',
  templateUrl: './mensagem-erro.component.html',
  styleUrls: ['./mensagem-erro.component.css']
})
export class MensagemErroComponent implements OnInit {

  @Input() title : String = ''
  @Input() subtitle : String = ''
  @Input() botao : any = 'Ok'
  @Input() reload : boolean = true;


  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  
  }

  close(){
    this.modalService.dismissAll()
    if(this.reload == true){
      location.reload()
    }
    else{
      return false;
    }

  }
}
