import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'app-footer-logado',
  templateUrl: './footer-logado.component.html',
  styleUrls: ['./footer-logado.component.css']
})
export class FooterLogadoComponent implements OnInit {
  isNavbarCollapsed=false;

  constructor( private router: Router,
    private toastr: ToastrService,
    private _http : RequisicoesService,
    @Inject(DOCUMENT) private document: any,
    private  pageScrollService : PageScrollService
   ) { }

  ngOnInit(): void {
  }
  irPagina(element){
  localStorage.setItem("shj.localHomeSite", element);

      if(element == "#login"){
        this.router.navigate(['/login']);

      }else if(this.router.url == "/home"){
        document.querySelector(element).scrollIntoView();

      }else{
        this.router.navigate(['/home']);

      }
     
     

  }


}
