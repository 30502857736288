 <div class="footer-fluid">
    <div class="row">
        <div class="col-md-3 mt-4 col-sm-12 d-flex justify-content-center align-items-center">
            <p class="dev-por">@copyright 2019 | Desenvolvido por:          
            <img src="/assets/logoalphacode.png">
            </p>
        </div>
        <div class="col-md-6 mt-4 col-sm-12 d-flex justify-content-center align-items-center">
            <label class="mr-4 click txtFooter" (click)="irPagina('#quem_somos')"> Quem somos</label>
            <label class="mr-4 click txtFooter" (click)="irPagina('#fale_conosco')"> Fale Conosco</label>
            <label class="mr-4 click txtFooter" (click)="irPagina('#como_funciona')"> Como funciona</label>
            <label class="mr-4 click txtFooter" (click)="irPagina('#vantagens')">  Vantagens</label>
        </div>
        <div class="col-md-3 mt-4 col-sm-12 mb-4 d-flex justify-content-center align-items-center">
            <img  class="img-responsive" style="width: 170px;" src="../assets/logo.png">
        </div>
    </div>
</div>
