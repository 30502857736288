import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { ToastrService } from 'ngx-toastr';
import { MensagemSucessoComponent } from '../mensagem-sucesso/mensagem-sucesso.component';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.css']
})
export class EsqueciSenhaComponent implements OnInit {
  redefinirForm : FormGroup
  cadastro : any = {}
  constructor(private formBuilder : FormBuilder, private modalService: NgbModal, public activeModal: NgbActiveModal, private https : RequisicoesService, private toastr: ToastrService) {  
   }

  ngOnInit(): void {
    
    this.redefinirForm = this.formBuilder.group({
      email : new FormControl('', Validators.required)
    })

  }

 async validacao():Promise<any> {
  return new Promise((resolve, reject) => {
    console.log("TESTE");

    
    if(this.redefinirForm.invalid){
      document.querySelector("#email").classList.add("campo-invalido");
      this.toastr.error('', 'Escreva um e-mail por favor.');
      resolve(false)
    }else {
      document.querySelector("#email").classList.remove("campo-invalido");
      resolve(true)
    }
  })
  }

 async enviar() {

    let validaBool = await this.validacao()
  
    
    if(validaBool) {
      var dados : any = {}

      dados = this.redefinirForm['value']

      dados.tipo = localStorage.getItem('shj.tipo')

      console.log(dados);
      
      this.https.post("esquecisenha", dados ).subscribe(
        async (resposta: any) => {
          console.log(resposta);
  
          if(resposta.status == 'sucesso') {
            console.log("TESTE");
            
            const modal = this.modalService.open(MensagemSucessoComponent,{
              size: 'sm'
            });
            modal.componentInstance.title = 'E-mail enviado com sucesso!' ;        
            
        
          
          }else{
            this.toastr.error('', resposta.mensagem);
          }
     
  
        }, error => {
          this.toastr.error('', 'Ocorreu um erro, tente novamente por favor!');
          console.log('erro na requisicao', error)
        })
    }
   
  }

}
