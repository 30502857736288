import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import { ConfirmacaoPedidoComponent } from '../confirmacao-pedido/confirmacao-pedido.component';


@Component({
  selector: 'app-exibir-valor-cliente',
  templateUrl: './exibir-valor-cliente.component.html',
  styleUrls: ['./exibir-valor-cliente.component.css']
})
export class ExibirValorClienteComponent implements OnInit {
  @Input() envio : boolean = false;

  public cliente: any = {};
  public param : any = {};
  
  constructor(
    private _http : RequisicoesService,
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal, 
    private router:Router,
    public httpClient: HttpClient,
    private toastr: ToastrService,
  ){ }
  ngOnInit(): void {
    this.param = JSON.parse(localStorage.getItem('shj.pedido_tipo_veiculo')); 
    this.cliente = JSON.parse(localStorage.getItem('shj.usuario')); 

    if(this.param == 1){
      this.param = this.cliente.tarifa_carro
    }else if (this.param == 2){
      this.param = this.cliente.tarifa_moto
    }else{
      this.param = this.cliente.tarifa_carro_utilitario
    }
  }
  
  prosseguirCancelar(bool){
    if(bool == true){
      this.modalService.dismissAll();
      this.modalService.open(ConfirmacaoPedidoComponent)
    }else{
      this.modalService.dismissAll();
    }   
  }
  // prosseguirCancelar(bool){
  //   if(bool == true){
  //     this.modalService.dismissAll();
  //     let modal = this.modalService.open(ConfirmacaoPedidoComponent, {
  //       size: 'md '
  //     });
  //   }else{
  //     this.modalService.dismissAll();
  //   }   
  // }

}
