<header class="">
    <nav *ngIf="!logado" class="navbar ml-0 navbar-expand-lg navbar-light" style="background-color: #fff;">
        <a class="navbar-brand ml-0 col-md-2 col-8" href="#"><img style="width:195px;height: 45px;background:cover;" src="../assets/logo.png" class=" img-fluid"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" (click)="isNavbarCollapsed = !isNavbarCollapsed" 
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse align-items-center" id="navbarNav" [ngClass]="(isNavbarCollapsed) ? 'show' : ''">
            <ul class="navbar-nav my-2 my-lg-12">
                
                <li class="nav-item active home">
                    <svg class="bi bi-circle-fill" style="color:transparent"  width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>    
                    <a class="nav-link flex"(click)="scrollToElement('#inicio')"(click)="isNavbarCollapsed = !isNavbarCollapsed" >Home</a>
                </li>
                <li class="nav-item">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>  
                    <a class="nav-link flex" (click)="scrollToElement('#quem_somos')">Quem somos</a>
                </li>
                <li class="nav-item">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>  
                    <a class="nav-link flex"(click)="scrollToElement('#como_funciona')" >  Como funciona</a>
                </li>
                <li class="nav-item">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>  
                    <a class="nav-link flex" (click)="scrollToElement('#download_app')" > Download do App</a>
                </li>
                <li class="nav-item">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>  
                    <a class="nav-link flex"  (click)="scrollToElement('#vantagens')"  >  Vantagens</a>
                </li>
                <li class="nav-item">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>  
                    <a class="nav-link flex" (click)="scrollToElement('#fale_conosco')">  Fale Conosco</a>
                </li>
                <li class="nav-item" style="min-width:85px;">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>  
                    <a class="nav-link flex"  (click)="scrollToElement('#login')"> Login  <img class="icone_login" src="assets/user-silhouette.png"></a>
                </li>
            </ul>
        </div>
    </nav>
    <nav *ngIf="entregador" class="navbar ml-0 navbar-expand-lg navbar-light" style="background-color: #fff;">
        <a class="navbar-brand ml-0 col-md-8 col-8" href="#"><img   style="width:195px;height: 45px;background:cover;" src="../assets/logo.png" class=" img-fluid"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavEntregador" (click)="isNavbarCollapsed = !isNavbarCollapsed" 
            aria-controls="navbarNavEntregador" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse " id="navbarNavEntregador"  [ngClass]="(isNavbarCollapsed) ? 'show' : ''">
            <ul class="navbar-nav my-2 my-lg-12" >
                <li class="nav-item item-menu">
                    <a class="nav-link flex" href="#/home-entregador"><b ngClass="{{home_entregador ? 'active' : ''}}">Home </b></a>
                </li>
                <li class="nav-item item-menu">
                    <svg class="bi bi-circle-fill" width=".5em" color="gray" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>    
                    <a class="nav-link" (click)="verificarClt(clt)"><b ngClass="{{historico_entregador ? 'active' : ''}}">Histórico </b>
                    </a>                      
                </li>   
            
                <li class="nav-item item-menu">
                    <svg class="bi bi-circle-fill"  width=".5em" color="gray" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>    
                    <a class="nav-link flex " href="#/perfil-entregador"><b ngClass="{{perfil_entregador ? 'active' : ''}}">Meu Perfil  </b> </a>
                </li>
                <!-- <li class="nav-item flex justify-content-center">
                    <svg class="bi bi-circle-fill"  width=".5em"  color="gray" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>  
                    <span class="nav-link flex" style="cursor:pointer;" (click)="logout()">Logout</span>
                    <div *ngIf="entregador.avatar" class="avatar-padrao avatar-header" [style.background]="'url('+ entregador.avatar  + ')'"></div>
                    <div *ngIf="!entregador.avatar" class="avatar-padrao avatar-header" [style.background]="'url(assets/user-silhouette.png)'" ></div>
                    <img *ngIf="isOnline == 0" class="on_off" src="assets/oval_off.png">
                    <img *ngIf="isOnline == 1" class="on_off" src="assets/Oval_on.png">
                </li>          -->
                <li class="nav-item ml-2 item-menu">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>
                    <span class="nav-link item-menu" style="cursor:pointer;" (click)="logout()">Logout</span>
                    <div *ngIf="entregador.avatar" class="avatar-padrao avatar-header" [style.background]="'url('+ entregador.avatar  + ')'"></div>
                    <div *ngIf="!entregador.avatar" class="avatar-padrao avatar-header" [style.background]="'url(assets/user-silhouette.png)'" ></div>
                    <img *ngIf="isOnline == 0" class="on_off" src="assets/oval_off.png">
                    <img *ngIf="isOnline == 1" class="on_off" src="assets/Oval_on.png">
                </li>  
            </ul>
        </div>
    </nav>
    <nav *ngIf="cliente" class="navbar navbar-expand-lg navbar-light " style="background-color: #fff;">
        <a class="navbar-brand col-md-2 col-8" href="#"><img style="width:195px;height: 45px;background:cover;" src="../assets/logo.png" class=" img-fluid"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavCliente" (click)="isNavbarCollapsed = !isNavbarCollapsed" 
            aria-controls="navbarNavCliente" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
         </button>
        <div class="collapse navbar-collapse " id="navbarNavCliente"  [ngClass]="(isNavbarCollapsed) ? 'show' : ''">
            <ul class="navbar-nav my-2 my-lg-12 nav-list" >
                <li class="nav-item item-menu" >
                <a class="nav-link " href="/#/home-cliente"><b ngClass="{{home ? 'active' : ''}}">Home </b></a>
                </li>
                <li class="nav-item ml-2 item-menu">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>
                    <a class="nav-link " href="/#/em-andamento-cliente">
                        <b ngClass="{{andamento ? 'active' : ''}}">Em Andamento</b>
                    </a>
                </li> 
                <li class="nav-item ml-2 item-menu">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>  
                    <a class="nav-link " href="/#/historico-cliente"><b ngClass="{{historico ? 'active' : ''}}">Histórico</b></a>
                </li>  
                <li class="nav-item ml-2 item-menu">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg> 
                    <a class="nav-link " href="/#/favoritos-cliente"><b ngClass="{{favoritos ? 'active' : ''}}">Favoritos</b></a>
                </li> 
                <li class="nav-item ml-2 item-menu">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>  
                    <a class="nav-link " href="/#/perfil-cliente"><b ngClass="{{perfil ? 'active' : ''}}">Meu Perfil</b> </a>
                </li>  
                <li class="nav-item ml-2 item-menu">
                    <svg class="bi bi-circle-fill" color="gray" width=".5em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8"/>
                    </svg>
                    <span class="nav-link item-menu" style="cursor:pointer;" (click)="logout()">Logout</span>
                    <!-- <img *ngIf="cliente.avatar"  class="padrao_img" src="{{cliente.avatar}}">
                    <img *ngIf="!cliente.avatar" src="assets/user-silhouette.png"> -->
                    <div *ngIf="cliente.avatar" class="avatar-padrao avatar-header" [style.background]="'url('+ cliente.avatar  + ')'"></div>
                    <div *ngIf="!cliente.avatar" class="avatar-padrao avatar-header" [style.background]="'url(assets/user-silhouette.png)'" ></div>
                </li>       
            </ul>
        </div>
    </nav>
</header>