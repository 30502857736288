import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/services/utils/util.service';
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';

@Component({
  selector: 'app-bloqueio-avaliacao',
  templateUrl: './bloqueio-avaliacao.component.html',
  styleUrls: ['./bloqueio-avaliacao.component.css']
})
export class BloqueioAvaliaçaoComponent implements OnInit {
  public cliente: any = {};
  public pedido: any = {};
  public acao: any = '';
  public entregador_id: any = '';
  public entregador: any = {};
  public pedido_id: any = '';
  public descricao: any = '';
  public rate: any = '';


  constructor(
    private _http : RequisicoesService,
    public httpClient: HttpClient,
    private modalService: NgbModal,
    private utils: UtilService,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.cliente = JSON.parse(localStorage.getItem('shj.usuario')); 
    this.entregador_id = localStorage.getItem('shj.entregador_id');
    this.acao = localStorage.getItem('shj.acao');
    this.buscaDados();
  }
 
  buscaDados(){
    this._http.get("entregadores", { entregador_id : this.entregador_id}).subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {
          this.entregador = resposta.data[0];
        }
      },
      erro => {
        console.log(erro);
      }
    );
  }
  bloquear(){
    console.log(this.descricao)
    if(this.descricao != ''){
      this._http.post("bloqueios", { id_entregador : this.entregador_id, id_cliente: this.cliente.id, motivo: this.descricao }).subscribe(
        async (resposta: any) => {
          if (resposta.status == "sucesso") {
            this.router.navigate(['/historico-cliente']);

          }
        },
        erro => {
          console.log(erro);
        }
      );
    }
    else{
      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'md '
      });
      modal.componentInstance.title = "Digite um motivo";      
      modal.componentInstance.reload = false;      
    }
  }
  avaliar(){
    console.log(this.descricao)
    if(this.descricao != ''){
      this._http.post("avaliacoes", { entregador_id : this.entregador_id, nota : this.rate, comentario: this.descricao }).subscribe(
        async (resposta: any) => {
          if (resposta.status == "sucesso") {
            this.router.navigate(['/historico-cliente']);
          }
        },
        erro => {
          console.log(erro);
        }
      );
    }
    else{
      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'md '
      });
      modal.componentInstance.title = "Digite um motivo";      
      modal.componentInstance.reload = false;      
    }
  }
}