<div class="row ml-2 mr-2" >
    <div  *ngFor="let pedido of pedidos" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-3">
        <div class="col-12 border-card">
            <div class="row">
                <div class="col-md-12 col-xs-12 col-sm-12">
                    <div class="row">
                        <div class="col-md-4 col-sm-3 col-xs-4" style="margin-top:9%;">
                            <img *ngIf="pedido.avatar" class="padrao_img" [src]="pedido.avatar" />
                            <img *ngIf="!pedido.avatar" class="padrao_img"  src="assets/user.png" />
                        </div>
                        <div class="col-md-8 col-sm-6 col-xs-8" style="margin-top:9%;">
                            <h6><b>Origem: </b></h6>
                            <h6><b>{{pedido.nome_fantasia}}</b></h6>
                            <p>{{pedido.endereco_cliente}}</p>
                            <p>{{pedido.bairro_cliente}} - {{pedido.cidade_cliente}}</p>
                            <p>Quilometragem: {{pedido.quilometragem}} Km</p>
                            <p>Valor : {{pedido.valor_estimado}}</p>

                        </div>
                    </div>
                    <div class="row bloco_botoes" *ngIf="pedido.status_id == 1">
                        <div>
                            <button class="btn-red btn-md  ml-2 mr-1"  (click)="aceitar(pedido)" color="none">
                                Aceitar
                            </button>
                        </div>
                        <div>
                            <button class="btn-red btn-md ml-2 mr-1"  (click)="recusar(pedido)" color="none">
                                Recusar
                            </button>
                        </div>
                    </div>
                    <div *ngIf="pedido.status_id != 1"  class="mr-4 mb-3" style="float:right;">
                        <span>{{pedido.nome_status}}</span>
                    </div>
                </div>
            </div>   
        </div>
    </div>
</div>
<div *ngIf="pedidos == false" class="container ">
    <span class='nenhum_pedido'>Nenhum pedido no momento.  </span>
</div>