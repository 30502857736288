import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask'
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';
import { ConfirmacaoPedidoComponent } from '../../modals/confirmacao-pedido/confirmacao-pedido.component';
import { ExibirValorClienteComponent } from '../../modals/exibir-valor-cliente/exibir-valor-cliente.component';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/services/utils/util.service';

@Component({
  selector: 'app-home-cliente',
  templateUrl: './home-cliente.component.html',
  styleUrls: ['./home-cliente.component.css']
})

export class HomeClienteComponent implements OnInit {
  public home:Boolean = true;
  public cliente:any = {};
  public veiculos: any = [];
  public tipo_veiculo : any = '';
  public enderecos : any = [];
  public pedido : any = [];
  public solicitacaoForm : any;
  public retorno_modal_pedido : any = '';
  public favoritos : any;

  constructor(
    private _http : RequisicoesService,
    public httpClient: HttpClient,
    private modalService: NgbModal,
    private utils: UtilService,
    private mask: NgxMaskModule
  ) {}

  ngOnInit(): void {
    //obtem os dados do cliente
    this.cliente = JSON.parse(localStorage.getItem('shj.usuario')); 
    console.log(this.cliente);   
    // Escreve o combo de tipos de veiculos
    this.buscaParamerizacao()
    //monta o formulario
    this.solicitacaoForm = new FormGroup({
      tipo_veiculo: new FormControl(this.tipo_veiculo),
      cep: new FormControl('', Validators.required),
      endereco: new FormControl('', Validators.required),
      numero:new FormControl('', Validators.required),
      complemento:new FormControl('')
    });
    //Reseta resquicio de pedido do localstorage caso nao venha de um retorno
    if(localStorage.getItem('shj.retorno') == '1'){
      this.enderecos = JSON.parse(localStorage.getItem('shj.pedido_enderecos'));
      this.solicitacaoForm.controls['tipo_veiculo'].setValue(localStorage.getItem('shj.pedido_tipo_veiculo'));
      this.tipo_veiculo = localStorage.getItem('shj.pedido_tipo_veiculo');
      this.favoritos = localStorage.getItem('shj.favoritos');
      localStorage.removeItem('shj.favoritos');
      localStorage.removeItem('shj.retorno');
     
    }
    else{
      localStorage.removeItem('shj.pedido_enderecos');
      localStorage.removeItem('shj.pedido_tipo_veiculo');
      localStorage.removeItem('shj.favoritos');
      localStorage.removeItem('shj.tipo');
    }
    
  }
  buscaParamerizacao(){
    //Busca os veiculos parametrizados na base
    this._http.get("parametrizacao").subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {
          this.veiculos = resposta.data;
          console.log("veiculos");
          console.log(this.veiculos);
        }
      },
      erro => {
        console.log(erro);
      }
      );
    }
    
    selectTipoVeiculo(){
      //salva o tipo selecinado em variável
      this.tipo_veiculo = this.solicitacaoForm.get("tipo_veiculo").value;
      // verifica quantos favoritos o usuário tem nessa categoria
      this.buscaFavoritos();
      // Muda objeto de endereco o tipo do veicula para nao ter divergencia
      if(this.enderecos.length > 0){
        this.enderecos.forEach(endereco => {
          endereco.tipo_id = this.tipo_veiculo;
        });
        console.log('mudanca de tipo veiculo')
        console.log(this.enderecos);
        console.log('------------------')
      }
    } 
    async cep() {
      console.log("teste")
      var x = this.solicitacaoForm.get("cep").value;
      this._http.get("cep", { 'cep': x })
      .subscribe(
        (resposta: any) => {
          if (resposta.status == 'sucesso') {
            var dados = JSON.parse(resposta.data);
            console.log(dados);
            if(!dados.error){
              let retorno_endereco = '';
              retorno_endereco += dados.success['tp_logradouro'] + " " + dados.success['logradouro'] + " - ";
              retorno_endereco += dados.success['bairro'] + " - ";
              retorno_endereco += dados.success['cidade'] + " - ";
              retorno_endereco += dados.success['uf'].toUpperCase();
              this.solicitacaoForm.controls['endereco'].setValue(retorno_endereco);
            }
            else{
              const modal = this.modalService.open(MensagemErroComponent, {
                size: 'md '
              });
              modal.componentInstance.title = "CEP Não encontrado!";      
              modal.componentInstance.reload = false;      
              this.solicitacaoForm.controls['endereco'].setValue('');
            }
          } else {
            this.solicitacaoForm.controls['endereco'].setValue('');
          }
        },
        (erro) => {
          console.log(erro)
        }
      )
  }
  adicionar() {
    // Adiciona um endereco
    console.log(this.enderecos);
    if(this.solicitacaoForm.valid) {
      // Salva o endereco adicionado no array
      console.log('aqui');
      var dados = {
        tipo_id: this.tipo_veiculo,
        cep: this.solicitacaoForm.get("cep").value,
        endereco: this.solicitacaoForm.get("endereco").value,
        numero: this.solicitacaoForm.get("numero").value,
        complemento: (this.solicitacaoForm.get("complemento").value == '' || this.solicitacaoForm.get("complemento").value == null)? 'Sem Complemento' : this.solicitacaoForm.get("complemento").value
      };
      console.log(dados);
      this.enderecos.push(dados);
      this.solicitacaoForm.reset();
      //Como o form é limpo, seleciono o veiculo que esta salvo no array no dropdown
      this.solicitacaoForm.controls['tipo_veiculo'].setValue(this.tipo_veiculo);
    }
    else{
      console.log('else');
      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'md '
      });
      modal.componentInstance.title = "Preencha os campos obrigatórios!";      
      modal.componentInstance.reload = false;      
      return false;
    }
  }

  remover(indice) {
    //Remove um endereco do array e da tela
    console.log(indice);
    this.enderecos.splice(indice, 1); 
  }
  
  solicitar(){
    // Apenas salva em localstorage para uso do modal de selecao de motorista
    if(!this.enderecos.length){
      //Caso nao haja enderecos
      console.log('if');
      let modal = this.modalService.open(MensagemErroComponent, {
        size: 'md '
      });
      modal.componentInstance.title = "Nenhum endereço inserido";      
      modal.componentInstance.reload = false;      
      return false;
    }
    else if(this.tipo_veiculo == '') { 
      //Caso nao tenha selecionado um veiculo
      console.log('else if');
      let modal = this.modalService.open(MensagemErroComponent, {
        size: 'md '
      });
      modal.componentInstance.title = "Nenhum tipo de veículo selecionado";      
      modal.componentInstance.reload = false;      
      return false;
    }
    else{
      //Monta o array de pedido
      localStorage.setItem('shj.pedido_enderecos', JSON.stringify(this.enderecos));
      localStorage.setItem('shj.pedido_tipo_veiculo', this.tipo_veiculo);
      localStorage.setItem('shj.favoritos', this.favoritos);

      //Abre a modal que seleciona possibilita escolher um motorista ou nao
      let modal = this.modalService.open(ExibirValorClienteComponent, {
        size: 'md '
      });
    }
  }
  buscaFavoritos(){
    console.log("favoritos");
    //Busca os motoristas na base
    this._http.get("favoritos", {cliente_id:this.cliente.id, tipo_id:this.tipo_veiculo}).subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {
          console.log(resposta)
          if(resposta.data == '') {
            this.favoritos = 0;
          }
          else{
            this.favoritos = resposta.data.length;
          }
          console.log(this.favoritos);
        }
        else{
          this.favoritos = 0;
        }
      },
      erro => {
        console.log(erro);
        this.favoritos = 0;
      }
    );
  }
}