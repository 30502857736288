<app-header [cliente]="cliente" [logado]="true"></app-header>
<div class="row container-pedido-andamento m-0 justify-content-between">
    <div class="col-lg-4 col-md-6 bloco-detalhes">
        <div class="col-md-12 status-pedido"><label>Status do Pedido: </label><span class="uppercase">
                {{ pedido.status }}</span></div>
        <div class='col-md-12 container-progress-bar'>
            <div class='col-md-3 progress-bar' [ngClass]="{'progress-bar-fill': barraUm, 'progress-bar-canceled': pedido.status_id == '7'}">
                <div *ngIf="LoadingUm" class='loading-bar'></div>
            </div>
            <div class='col-md-3 progress-bar' [ngClass]="{'progress-bar-fill': barraDois, 'progress-bar-canceled': pedido.status_id == '7'}">
                <div *ngIf="LoadingDois" class='loading-bar'></div>
            </div>
            <div class='col-md-3 progress-bar' [ngClass]="{'progress-bar-fill': barraTres, 'progress-bar-canceled': pedido.status_id == '7'}">
                <div *ngIf="LoadingTres" class='loading-bar'></div>
            </div>
            <div class='col-md-3 progress-bar' [ngClass]="{'progress-bar-fill': barraQuatro, 'progress-bar-canceled': pedido.status_id == '7'}">
                <div *ngIf="LoadingQuatro" class='loading-bar'></div>
            </div>
        </div>
        <div class="col-md-12 valor-pedido"><label>Valor Frete: </label><span class="uppercase">
                {{ pedido.valor_frete }}</span></div>
        <div class="col-12">
            <h5>Origem:</h5>
        </div>
        <!-- <div class="col-12"> -->
        <div class="col-md-12 box-pedido">
            <div class="col-2">
                <div *ngIf="pedido.avatar" class="avatar" [style.background]="'url('+ pedido.avatar  + ')'">
                </div>
                <div *ngIf="!pedido.avatar" [style.background]="'url(assets/user.png)'" class="avatar"></div>
            </div>
            <div *ngIf="pedido.nome" class="col-8">
                <h6><b>{{pedido.nome}}</b></h6>
                <p>{{pedido.marca}} / {{pedido.modelo}}</p>
                <p>Placa: {{pedido.placa}}</p>
            </div>
            <div *ngIf="!pedido.nome" class="col-8">
                <h6><b>Agurando o aceitar!</b></h6>
            </div>
            <div class="col-2 div-heart">
                <img *ngIf="pedido.favoritado == 1" class="heart" src="../../../assets/heart.svg" alt="">
                <img *ngIf="pedido.favoritado == 0" class="heart" src="../../../assets/heart2.svg" alt="">
            </div>
        </div>
        <!-- </div> -->

        <div class="col-12 mt-5">
            <h5>Endereços:</h5>
        </div>
        <div *ngFor="let endereco of pedido.rotas; let i = index" class="col-md-12 box-pedido">
            <div class="col-2">
                <img *ngIf="endereco.status == 1" class="marker" src="../../../assets/marker.png" alt="">
                <img *ngIf="endereco.status == 6" class="marker" src="../../../assets/localizacao.png" alt="">
            </div>
            <div class="col-10">
                <p>{{endereco.endereco}}, {{endereco.numero}}</p>
                <p>{{endereco.complemento}}</p>
                <p>{{endereco.cep}}</p>
            </div>
        </div>
        <div *ngIf="tipo == 'em_andamento'" class="row mt-4" style="margin-bottom: 100px;">
            <div class="col-3">
                <app-botao-vermelho (click)="cancelar()" title="Cancelar"></app-botao-vermelho>
            </div>
        </div>
        <div *ngIf="tipo == 'historico'" class="row mt-4 mb-4">
            <div class="col-3">
                <app-botao-vermelho (click)="avaliar(pedido.entregador_id)" title="Avaliar"></app-botao-vermelho>
            </div>
        </div>
    </div>
    <div class="col-lg-7 col-md-12 offset-md-0 p-0">
        <agm-map [latitude]="pedido.latitude_cliente" [longitude]="pedido.longitude_cliente" [zoom]="10">
            <agm-marker *ngIf="!!pedido.entregador_id && tipo != 'historico'" [iconUrl]="icone_cinza"
                [latitude]="pedido.entregador_latitude" [longitude]="pedido.entregador_longitude"></agm-marker>
            <agm-marker *ngIf="pedido.status_id >= 3" [iconUrl]="icone_v_check" [latitude]="pedido.origem_latitude"
                [longitude]="pedido.origem_longitude"></agm-marker>
            <agm-marker *ngIf="pedido.status_id <= 2" [iconUrl]="icone_vermelho" [latitude]="pedido.origem_latitude"
                [longitude]="pedido.origem_longitude"></agm-marker>
            <agm-marker *ngFor="let rota of rotas" [iconUrl]="rota.icone_maker" [latitude]="rota.latitude"
                [longitude]="rota.longitude"></agm-marker>
        </agm-map>
    </div>
</div>
<app-footer-logado></app-footer-logado>