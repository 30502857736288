import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }



  async checkEmptyFields(object: object, fields: Array<any>) {
    let data = [];
    var count_empty = 0;

    fields.forEach(field => {
      if (!object.hasOwnProperty(field) || object[field] == "") {
        count_empty += 1;
        data.push({ status: "empty", field });
      } else {
        data.push({ status: "filled", field });
      }
    });

    if (count_empty > 0) {
      console.log(data);

      return data;
    } else {
      return false;
    }
  }
  async validaEmail(email: string): Promise<boolean> {
    let er = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2}/;
    if (er.exec(email)) {
      return true;
    } else {
      return false;
    }
  }

  getPosition(): Promise<any>  {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
      },
      err => {
        let locallizacao = {
          lat: 0,
          lng: 0
        }
        localStorage.setItem('localizacao', JSON.stringify(locallizacao));
        reject(err);
      });
    });
  }

}
