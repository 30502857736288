import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/services/utils/util.service';
import { ConfirmacaoPedidoComponent } from '../../modals/confirmacao-pedido/confirmacao-pedido.component';

@Component({
  selector: 'app-favoritos',
  templateUrl: './favoritos.component.html',
  styleUrls: ['./favoritos.component.css']
})

export class FavoritosComponent implements OnInit {
  public cliente_id:any;
  public cliente: any = {};
  public favoritos: any = [];
  public tipo_veiculo : any;
  public horario_entregador : any = {};
  public solicitacaoForm : any;
  public rate : Number;
  public motorista_selecionado : any = 0;
  public solicitacao : any = {
    rotas : {},
    pedido : {}
  };
  public envia : Boolean = false;

  constructor(
    private _http : RequisicoesService,
    public httpClient: HttpClient,
    private modalService: NgbModal,
    private utils: UtilService,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.cliente = localStorage.getItem('shj.usuario'); 
    this.cliente_id = localStorage.getItem('shj.id'); 
    this.tipo_veiculo = localStorage.getItem('shj.pedido_tipo_veiculo'); 
    
    this.buscaMotorista();
    this.solicitacaoForm = new FormGroup({
      tipo_veiculo: new FormControl(this.tipo_veiculo),
      cep: new FormControl('', Validators.required),
      endereco: new FormControl('', Validators.required),
      numero:new FormControl('', Validators.required),
      complemento:new FormControl('')
    });
  }

  voltarHome(){
    localStorage.setItem('shj.retorno', '1');
    this.router.navigate(['/home-cliente']);
  }
  
  buscaMotorista(){
    //Busca os motoristas na base
    this._http.get("favoritos", {cliente_id:this.cliente_id, tipo_id:this.tipo_veiculo}).subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {
          this.favoritos = resposta.data;
          this.formataArray();
          console.log(this.favoritos);
        }
      },
      erro => {
        console.log(erro);
      }
    );
  }
  
  formataArray(){
    //Percorre o array de favoritos
    this.favoritos.forEach((entregador, index) => {
      //busco os horario cadastrados
      this._http.get("horarios", {entregador_id: entregador.entregador_id}).subscribe(
        async (resposta: any) => {
          if (resposta.status == "sucesso") {
            var dia_semana = new Date().toLocaleDateString('pt-BR', { weekday: 'long' });
            dia_semana = dia_semana.toString();
            dia_semana = dia_semana.replace("-", "_")
            this.favoritos[index].horario = resposta.data[dia_semana].horario_inicial.replace(":", "h") +" às "+ resposta.data[dia_semana].horario_final.replace(":", "h");
          }
          else{
            this.favoritos[index].horario = 'Sem horários cadastrados';
          }
        },
        erro => {
          console.log(erro);
        }
      );
    });
  }

  seleciona(id){
    this.motorista_selecionado = id;
    console.log(id);
  }

  solicitar(){
    if(this.motorista_selecionado != 0){

      console.log(localStorage.getItem('shj.id'));
      this.solicitacao.rotas = JSON.parse(localStorage.getItem('shj.pedido_enderecos')); 
      this.solicitacao.pedido = {
        cliente_id : Number(localStorage.getItem('shj.id')),
        status_id : 1,
        status_pagamento : 'pendente',
        entregador_id : this.motorista_selecionado
      }
      
      console.log(this.solicitacao);
  
      this._http.post("pedidos", this.solicitacao).subscribe(
        async (resposta: any) => {
  
          console.log("Resposta do método de pedido ", resposta);
  
          if (resposta.status == "sucesso") {
            console.log('pedido enviado')
            let modal = this.modalService.open(ConfirmacaoPedidoComponent, {
              size: 'md '
            });
            modal.componentInstance.envio = true;
          } else {
            this.modalService.dismissAll();
            const modal = this.modalService.open(MensagemErroComponent, {
              size: 'sm'
            });
            console.log(resposta.mensagem);
            
            modal.componentInstance.title = resposta.mensagem;     
            return false;
          }
        },
        erro => {
          this.modalService.dismissAll();
          const modal = this.modalService.open(MensagemErroComponent, {
            size: 'sm'
          });
          modal.componentInstance.title = "Erro ao fazer login!";     
          console.log(erro);
        }
      );
    }
    else{
        this.modalService.dismissAll();
        const modal = this.modalService.open(MensagemErroComponent, {
          size: 'sm'
        });
        modal.componentInstance.title = 'Favor Selecionar Um Entregador!';     
        modal.componentInstance.reload = false;     
        return false;
      
    }
  }
}