<app-header [logado]="false"></app-header>
<div class="pl-5 ml-5 pt-5 mr-5 pr-5">
    <form #form="ngForm">
        <div class="row">
            <div class="col-md-12  mt-4">
                <p><span>Dados pessoais</span></p>
                <img *ngIf="cliente.avatar" (click)="abrirArquivoAvatar()" class="padrao_img mr-3" src="{{cliente.avatar}}"/>
                    <img *ngIf="!cliente.avatar" (click)="abrirArquivoAvatar()" class="imgavatar mr-3" src="/assets/usuario_default.png"/>
                    <input type="file" style="display:none;" id="imagem" (change)="imageSelectedAvt($event.target.files)" class="form-control hide " >

                <span >Faça upload da imagem</span>
            </div>
            <div class="col-md-3 mt-4 col-lg-3">
                <label>Razão Social</label>
                <input class="form-control" name="clientes.razao_social" [(ngModel)]="cliente.razao_social" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 mt-4 col-lg-3">
                <label>Nome Fantasia</label>
                <input class="form-control" name="clientes.nome_fantasia" [(ngModel)]="cliente.nome_fantasia" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 mt-4 col-lg-3">
                <label>CNPJ</label>
                <input class="form-control" name="clientes.cnpj" [(ngModel)]="cliente.cnpj" mask="00.000.000/0000-00" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 mt-4 col-lg-3">
                <label>Celular</label>
                <input class="form-control"  name="clientes.celular" [(ngModel)]="cliente.celular"  mask="(00) 00000-0000" placeholder="Digite aqui" />
            </div>
        </div>
        
        <div class="row mt-4 pt-4" >
            <div class="col-md-12">
                <span>Endereço</span>
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>CEP</label>
                <input class="form-control" (blur)="cep();" mask="00000-000" name="clientes.cep" [(ngModel)]="cliente.cep"  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">

                <label>Endereço</label>
                <input class="form-control"     name="clientes.endereco" [(ngModel)]="cliente.endereco" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Número</label>
                <input class="form-control"  name="clientes.numero" [(ngModel)]="cliente.numero" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Complemento</label>
                <input class="form-control"   name="clientes.complemento" [(ngModel)]="cliente.complemento"    placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Bairro</label>
                <input class="form-control"    name="clientes.bairro" [(ngModel)]="cliente.bairro"  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Cidade</label>
                <input class="form-control"   name="clientes.cidade" [(ngModel)]="cliente.cidade"    placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Estado</label> 
                <select class="form-control" placeholder="Escolha"  name="clientes.estado" [(ngModel)]="cliente.estado" >
                    <option value="{{estado}}" *ngFor="let estado of estados"> {{estado}}</option>
                </select>
            </div>
        </div>

        <div class="row mt-4 pt-4">
            <div class="col-md-12">
                <span>Dados de Acesso</span>
            </div>
       
            <div class="col-md-3 pt-4">
                <label>E-mail</label>
                <input class="form-control"   (blur)='PesquisarEmail();' name="clientes.email" [(ngModel)]="cliente.email" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 pt-4">
                <label>Senha</label>
                <input class="form-control" name="clientes.senha" [(ngModel)]="cliente.senha" type="password" (blur)='verificarSenha();'  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 pt-4">
                <label>Confimar sua senha</label>
                <input class="form-control" (blur)='verificarSenha();' #confirmar_senha type="password" placeholder="Digite aqui" />
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-md-12">
                <div class="centralizar">
                   <button class="submit"  (click)="salvar()" type="submit"><app-botao-vermelho  title="Salvar"></app-botao-vermelho></button>

                </div>
            </div>
        </div> 
    </form>
</div>