import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { UtilService } from '../services/utils/util.service';
import { MensagemSucessoComponent } from '../modals/mensagem-sucesso/mensagem-sucesso.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RequisicoesService } from '../services/requisicoes/requisicoes.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT, ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  constructor(public utils : UtilService, private modalService: NgbModal, private router: Router,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: any,
    private _http : RequisicoesService,
    private  pageScrollService : PageScrollService,
    ) { 
     
  }
  @HostListener("window:unload", ["$event"]) unloadHandler(event: Event) {
    localStorage.setItem('shj.localHomeSite','#inicio')
    document.getElementById("inicio").scrollIntoView();

    console.log("Processing beforeunload...");
 }   


  ngOnInit(): void {
     if(localStorage.getItem("shj.localHomeSite") != "" && localStorage.getItem("shj.localHomeSite") != undefined ){
      document.querySelector(localStorage.getItem("shj.localHomeSite")).scrollIntoView();

     }
    
  }

  
  open() {
    this.modalService.open(MensagemSucessoComponent, {
      size: 'sm'
    });
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: Event) {
      localStorage.removeItem("shj.localHomeSite");
    }
}