import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/services/utils/util.service';
import { ConfirmacaoPedidoComponent } from '../../modals/confirmacao-pedido/confirmacao-pedido.component';

@Component({
  selector: 'app-favoritos-cliente',
  templateUrl: './favoritos-cliente.component.html',
  styleUrls: ['./favoritos-cliente.component.css']
})

export class FavoritosClienteComponent implements OnInit {
  public cliente_id:any;
  public cliente: any = {};
  public favoritos: any = [];
  public tipo_veiculo : any;
  public horario_entregador : any = {};
  public solicitacaoForm : any;
  public rate : Number;
  public motorista_selecionado : any = 0;
  public solicitacao : any = {
    rotas : {},
    pedido : {}
  };
  public envia : Boolean = false;

  constructor(
    private _http : RequisicoesService,
    public httpClient: HttpClient,
    private modalService: NgbModal,
    private utils: UtilService,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.cliente = localStorage.getItem('shj.usuario'); 
    this.cliente_id = localStorage.getItem('shj.id'); 
    
    this.buscaMotorista();
  }
  
  buscaMotorista(){
    //Busca os motoristas na base
    this._http.get("favoritos", {cliente_id:this.cliente_id}).subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {
          this.favoritos = resposta.data;
          console.log(this.favoritos);
        }
      },
      erro => {
        console.log(erro);
      }
    );
  }
  


  remover(id){
    this.motorista_selecionado = id;

    this._http.delete("favoritos", {id:this.motorista_selecionado}).subscribe(
      async (resposta: any) => {
        location.reload();
      },
      erro => {
        console.log(erro);
      }
      );
  }
}