import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';



@Component({
  selector: 'app-confirmacao-pedido',
  templateUrl: './confirmacao-pedido.component.html',
  styleUrls: ['./confirmacao-pedido.component.css']
})
export class ConfirmacaoPedidoComponent implements OnInit {
  @Input() envio : boolean = false;

  public cliente_id:any;
  public cliente: any = {};
  public favoritos: any = [];
  public tipo_veiculo : any;
  public envia : Boolean = false;
  public solicitacao : any = {
    rotas : {},
    pedido : {}
  };
 
  constructor(
    private _http : RequisicoesService,
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal, 
    private router:Router,
    public httpClient: HttpClient,
    private toastr: ToastrService,
  ){ }
  ngOnInit(): void {
    this.favoritos = Number(localStorage.getItem('shj.favoritos'));
    if(this.envio == true){
      this.envia = true;
    }
  }
  
  selecaoMototista(){
    if(this.favoritos > 0){
      this.router.navigate(['/favoritos']);
      this.modalService.dismissAll();
    }
    else{
      console.log('deu bom?');
      this.toastr.error('Voce ainda nao possui motoristas favoritados nessa categoria');
    }
  }
  
  solicitar(){
    console.log(localStorage.getItem('shj.id'));
    this.solicitacao.rotas = JSON.parse(localStorage.getItem('shj.pedido_enderecos')); 
    this.solicitacao.pedido = {
      cliente_id : Number(localStorage.getItem('shj.id')),
      status_id : 1,
      status_pagamento : 'pendente'
    }
    
    console.log(this.solicitacao);

    this._http.post("pedidos", this.solicitacao).subscribe(
      async (resposta: any) => {

        console.log("Resposta do método de pedido ", resposta);

        if (resposta.status == "sucesso") {
          console.log('pedido enviado')
          this.envia = true;
        } else {
          this.modalService.dismissAll();
          const modal = this.modalService.open(MensagemErroComponent, {
            size: 'sm'
          });
          console.log(resposta.mensagem);
          
          modal.componentInstance.title = resposta.mensagem;     
          return false;
        }
      },
      erro => {
        this.modalService.dismissAll();
        const modal = this.modalService.open(MensagemErroComponent, {
          size: 'sm'
        });
        modal.componentInstance.title = "Erro ao enviar pedido. Tente novamente mais tarde.";     
        console.log(erro);
      }
    );
  }

  finalizar(){
    this.modalService.dismissAll();
    if(this.router.url === '/home-cliente'){
      location.reload()
    }
    else{
      this.router.navigate(['/home-cliente']);
    }
  }

}
