// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};
export const globals = {
  isOnline: 0

}
// export const API_URL = "http://shj.mac/"
// export const API_URL = "http://shj.local/"
// export const API_URL = "https://shj.alphacode.mobi/"
export const API_URL = "https://shj.alphacode.com.br/"
