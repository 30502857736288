import { Component, OnInit } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MensagemErroComponent } from '../modals/mensagem-erro/mensagem-erro.component';
import { EsqueciSenhaComponent } from '../modals/esqueci-senha/esqueci-senha.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequisicoesService } from '../services/requisicoes/requisicoes.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public etapa1 :any = true;
  public etapa2 :any = false;
  public trabalhe: any = false;
  public cadastro: any = false;
  login: FormGroup;
  public Login: any = {};
  public tipo: string;
  constructor(
    private formBuilder : FormBuilder,
    private modalService: NgbModal,
    private http : RequisicoesService,
    private router:Router

  ) { }

  ngOnInit(): void {

    this.login = this.formBuilder.group({
      email: new FormControl('', Validators.required),
      senha: new FormControl('', Validators.required),
     
    });     
  }
  proximo(tipo){
    this.etapa1 = false;
    this.etapa2 = true;
    this.tipo = tipo;
    if(tipo == "entregadores"){
      this.trabalhe = true;
    } else {
      this.cadastro = true;
    }

    localStorage.setItem('shj.tipo', this.tipo)
  }

  trabalheConosco(tipo){
    console.log("teste");
    if(tipo == "entregadores"){
      this.router.navigate(['/cadastro-entregador']);
    } else {
      this.router.navigate(['/cadastro-cliente']);
    }
    
  }
  logar(){
    console.log(this.login);
    
    if (this.login.invalid) {

      Object.keys(this.login.controls).forEach(control => {
        if (control !== null) {
          if (this.login.get(control).invalid) {
            console.log("kk");
            if (document.querySelector("#" + control) != undefined) {
              document
                .querySelector("#" + control)
                .classList.add("campo-invalido");
            }
          } else {
            // console.log(control);
            if (
              document
                .querySelector("#" + control)
                .classList.contains("campo-invalido")
            ) {
              document
                .querySelector("#" + control)
                .classList.remove("campo-invalido");
            }
          }

        }

      });
    
      
      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'sm'
      });
      modal.componentInstance.title = "Preencha todos os campos";
      return false;
     }
     this.login['value']['tipo'] = this.tipo;
     console.log(this.login);
     
     this.http.post("login", this.login['value']).subscribe(
      async (resposta: any) => {

        console.log("Resposta do método de login ", resposta);

        if (resposta.status == "sucesso") {
          
         
          localStorage.setItem("shj.id", resposta.data.cliente.id); 
          localStorage.setItem("shj.usuario", JSON.stringify(resposta.data.cliente));
          localStorage.setItem('shj.tipoacesso', this.tipo)
          localStorage.setItem('shj.ambiente', resposta.data.cliente.ambiente)

          if( this.tipo == 'entregadores' ){
            localStorage.setItem('shj.veiculos',  JSON.stringify(resposta.data.veiculos));

            this.router.navigate(['/home-entregador']);

          } else{
            this.router.navigate(['/home-cliente']);
          }
        } else {
          const modal = this.modalService.open(MensagemErroComponent, {
            size: 'sm'
          });
          console.log(resposta.mensagem);
          
          modal.componentInstance.title = resposta.mensagem;     
          return false;
        }
      },
      erro => {
        const modal = this.modalService.open(MensagemErroComponent, {
          size: 'sm'
        });
        modal.componentInstance.title = "Erro ao fazer login!";     
        console.log(erro);
      }
    );

   
    }

    abrirEsqueciSenha(){
      const modal = this.modalService.open(EsqueciSenhaComponent, {
        size: 'sm'
      });
    }
     
  }

