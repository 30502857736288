import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components.module';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { HomeClienteComponent } from './cliente/home-cliente/home-cliente.component';
import { FavoritosComponent } from './cliente/favoritos/favoritos.component';
import { EmAndamentoClienteComponent } from './cliente/em-andamento-cliente/em-andamento-cliente.component';
import { PedidoAndamentoComponent } from './cliente/pedido-andamento/pedido-andamento.component';
import { HomeEntregadorComponent } from './entregador/home-entregador/home-entregador.component';
import { HistoricoComponent } from './entregador/historico/historico.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfilEntregadorComponent } from './entregador/perfil-entregador/perfil-entregador.component';
import { NgxMaskModule } from 'ngx-mask'
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { CadastroClienteComponent } from './cadastro-client/cadastro-cliente.component';
import { CadastroEntregadorComponent } from './cadastro-entregador/cadastro-entregador.component';
import { VerSolicitacaoComponent } from './entregador/ver-solicitacao/ver-solicitacao.component';
import { AgmCoreModule } from '@agm/core';
import { BarRatingModule } from "ngx-bar-rating";
import { PerfilClienteComponent } from './cliente/perfil-cliente/perfil-cliente.component';
import { HistoricoClienteComponent } from './cliente/historico-cliente/historico-cliente.component';
import { FavoritosClienteComponent } from './cliente/favoritos-cliente/favoritos-cliente.component';
import { BloqueioAvaliaçaoComponent } from './cliente/bloqueio-avaliacao/bloqueio-avaliacao.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HomeEntregadorComponent,
    HomeClienteComponent,
    HistoricoComponent,
    PerfilEntregadorComponent,
    CadastroClienteComponent,
    CadastroEntregadorComponent,
    VerSolicitacaoComponent,
    FavoritosComponent,
    PerfilClienteComponent,
    EmAndamentoClienteComponent,
    PedidoAndamentoComponent,
    HistoricoClienteComponent,
    FavoritosClienteComponent,
    BloqueioAvaliaçaoComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule.forRoot({duration: 1500}),
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBLVDeusb1rerAm2MkxMNMbKE1hpnLOPaA",
      libraries: ['ver-solicitacoes','pedido-andamento'] 

    }),
    BarRatingModule
  ],
  providers: [{provide:LocationStrategy, useClass: HashLocationStrategy } ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class AppModule { }
