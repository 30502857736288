<app-header [cliente]="cliente" [favoritos]="true" [logado]="true"></app-header>
<div class="container-fluid container-principal container-favoritos">
    <div class="row">
        <h5>Abaixo os motoristas favoritados</h5>
    </div>

        <div *ngFor="let favorito of favoritos; let i = index"  class="display-flex container-box col-12">
            <div class="col-md-6 box-favorito">
                <div class="col-2">
                    <div *ngIf="favorito.avatar" class="avatar" [style.background]="'url('+ favorito.avatar  + ')'">
                    </div>
                    <div *ngIf="!favorito.avatar" [style.background]="'url(assets/user.png)'" class="avatar" ></div>
                </div>
                <div class="col-8">
                    <p>{{favorito.nome}}</p>
                    <div class="col-4 p-0">
                        <bar-rating [rate]="favorito.media" [theme]="'stars'" [max]="5" disabled></bar-rating>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <img class="btn-fechar" (click)="remover(favorito.f_id)" src="../assets/close.png" alt="">
            </div>
        </div>

</div>
<app-footer-logado></app-footer-logado>