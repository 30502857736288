<app-header></app-header>
<div class="background">
        <div class="container">
            <div class="row align-items-center pt-5 pb-5 ">
            <div class="col-md-4 offset-md-6 col-sm-8 col-10 pb-5 flex div-login">
                <div class="row">
                    <div class="col-md-12 centralizar logo">
                        <img style=" width: 200px!important;height: auto;background-size: cover;"src="../assets/logo.png" >
                    </div>
                </div>
                <div class="etapa1 width-100 flex" [style.display]="etapa1 == true ? '' : 'none'">
                    <app-botao-vermelho class="pt-5" (click)="proximo('clientes')" title="Sou Cliente"></app-botao-vermelho>
                    <app-botao-vermelho class="p-4" (click)="proximo('entregadores')"  title="Sou Entregador"></app-botao-vermelho>
                </div>
               <div class="etapa2 width-100 flex p-4" [style.display]="etapa2 == true ? '' : 'none'">
                    <form class="width-100 flex" [formGroup]="login" >
                        <input class="form-control input"  formControlName="email" placeholder="E-mail"/>
                        <input class="form-control input" formControlName="senha" type="password" style="margin-top: 5%;" placeholder="Senha"/>
                        <a class="esqueci_senha center text-center p-2" (click)="abrirEsqueciSenha()"> Esqueceu a senha? <strong>Clique aqui</strong></a>
                        <app-botao-vermelho (click)="logar()" class="p-4"  title="Entrar"></app-botao-vermelho>
                        <span class="trabalhe_conosco" (click)="trabalheConosco('entregadores');" [style.display]="trabalhe == true ? '' : 'none'" ><b>Trabalhe Conosco</b></span>
                        <span class="trabalhe_conosco" (click)="trabalheConosco('clientes');" [style.display]="cadastro == true ? '' : 'none'" ><b>Cadastre-se</b></span>

                    </form>
                   
               </div>
                
            </div>
        </div>
    </div>
   
   
</div>