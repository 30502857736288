import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/services/utils/util.service';
import { ActivatedRoute } from '@angular/router';
import { MensagemConfirmacaoComponent } from '../../modals/mensagem-confirmacao/mensagem-confirmacao.component';


@Component({
  selector: 'app-pedido-andamento',
  templateUrl: './pedido-andamento.component.html',
  styleUrls: ['./pedido-andamento.component.css']
})
export class PedidoAndamentoComponent implements OnInit {
  public pedido: any = {};
  public cliente: any = {};
  public pedido_id: any;
  public tipo: any;
  public rotas: any = {};
  public icone_cinza = {
    url: 'assets/marker-cinza.png',
    scaledSize: {
      width: 30,
      height: 40
    }
  };
  public icone_vermelho = {
    url: 'assets/marker.png',
    scaledSize: {
      width: 30,
      height: 40
    }
  };
  public icone_v_check = {
    url: 'assets/localizacao.png',
    scaledSize: {
      width: 30,
      height: 40
    }
  };

  public barraUm: boolean = false;
  public barraDois: boolean = false;
  public barraTres: boolean = false;
  public barraQuatro: boolean = false;

  public LoadingUm: boolean = false;
  public LoadingDois: boolean = false;
  public LoadingTres: boolean = false;
  public LoadingQuatro: boolean = false;

  constructor(
    private _http: RequisicoesService,
    public httpClient: HttpClient,
    private modalService: NgbModal,
    private utils: UtilService,
    private router: Router,
    private active: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.cliente = JSON.parse(localStorage.getItem('shj.usuario'));
    this.pedido_id = localStorage.getItem('shj.pedido_id');
    this.tipo = localStorage.getItem('shj.tipo');
    console.log(this.pedido_id);
    console.log(this.tipo);
    this.buscaPedido();
  }

  buscaPedido() {
    this._http.get("historico", { id_pedido: this.pedido_id, tipo_requisicao: this.tipo }).subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {
          this.pedido = resposta.data[0];
          this.pedido.rotas = JSON.parse(this.pedido.todas_rotas);
          console.log("pedidos");
          console.log(this.pedido);

          this.pedido.status = this.statusPedido(this.pedido.status_id);

          this.pedido.latitude_cliente = Number(this.pedido.latitude_cliente);
          this.pedido.longitude_cliente = Number(this.pedido.longitude_cliente);
          this.pedido.origem_latitude = this.pedido.latitude_cliente;
          this.pedido.origem_longitude = this.pedido.longitude_cliente;
          this.rotas = this.pedido.rotas;

          this.pedido.rotas.forEach((rota, index) => {
            console.log("status da rota");
            console.log(rota);
            if (rota.status == "6") {
              console.log(this.rotas[index].icone);
              this.pedido.rotas[index].icone_maker = {
                url: 'assets/localizacao.png',
                scaledSize: {
                  width: 30,
                  height: 40
                }
              };
            }
            else {
              this.rotas[index].icone_maker = {
                url: 'assets/marker.png',
                scaledSize: {
                  width: 30,
                  height: 40
                }
              };
            }
          });

          // this.active.params.subscribe(params => {
          //   console.log('params');
          //   console.log(params);
          //   this.rotas = this.pedido.rotas;
          //   console.log(this.rotas  );
          //   this.pedido.rotas.forEach((rota, index) => {
          //     console.log("status da rota");
          //     console.log(rota.status);
          //     if(rota.status ==  "6"){
          //       console.log(this.rotas[index].icone);
          //       this.pedido.rotas[index].icone_maker = {
          //         url: 'assets/localizacao.png',
          //         scaledSize: {
          //             width: 30,
          //             height: 40
          //         }
          //       };
          //     }
          //     else{
          //       this.rotas[index].icone_maker ={
          //         url: 'assets/marker.png',
          //         scaledSize: {
          //             width: 30,
          //             height: 40
          //         }
          //       };
          //     }
          //   });
          // })
        }
      },
      erro => {
        console.log(erro);
      }
    );
  }

  cancelar() {
    console.log(this.pedido);
    let modal = this.modalService.open(MensagemConfirmacaoComponent, {
      size: 'md '
    });
    modal.componentInstance.titulo = 'Deseja cancelar o pedido';
    modal.componentInstance.mensagem = 'Para esta ação será cobrada, uma taxa de cancelamento no valor de : ' + this.pedido.valor_cancelamento + '. Essa açao não poderá ser desfeita!';
    modal.componentInstance.pedido_id = this.pedido_id;
  }

  avaliar(id) {
    localStorage.setItem('shj.entregador_id', this.pedido.entregador_id);
    localStorage.setItem('shj.acao', 'avaliar');
    this.router.navigate(['/bloqueio-avaliacao']);
  }

  statusPedido(status) {
    switch (status) {
      case '1':
        this.barraUm = this.barraDois = this.barraTres = this.barraQuatro = false;
        this.LoadingDois = this.LoadingTres = this.LoadingQuatro = false;
        this.LoadingUm = true;
        return 'Pendente de aceitação';
        break;

      case '2':
        this.barraUm = this.barraDois = this.barraTres = true;
        this.LoadingUm = this.LoadingDois = this.LoadingTres = false;
        this.barraQuatro = false;
        this.LoadingQuatro = true;
        return 'Em andamento';
        break;

      case '3':
        this.barraUm = this.barraDois = true;
        this.barraTres = this.barraQuatro = false;
        this.LoadingUm = this.LoadingDois = this.LoadingQuatro = false;
        this.LoadingTres = true;
        return 'Em Trânsito';
        break;

      case '4':
        this.barraDois = this.barraTres = this.barraQuatro = false;
        this.LoadingUm = this.LoadingTres = this.LoadingQuatro = false;
        this.barraUm = true;
        this.LoadingDois = true;
        return 'Entregador a Caminho';
        break;

      case '5':
        this.barraUm = this.barraDois = this.barraTres = this.barraQuatro = false;
        this.LoadingDois = this.LoadingTres = this.LoadingQuatro = false;
        this.LoadingUm = true;
        return 'Recusado';
        break;

      case '6':
        this.barraUm = this.barraDois = this.barraTres = this.barraQuatro = true;
        this.LoadingUm = this.LoadingDois = this.LoadingTres = this.LoadingQuatro = false;
        return 'Pedido Concluído';
        break;

      case '7':
        this.barraUm = this.barraDois = this.barraTres = this.barraQuatro = false;
        this.LoadingUm = this.LoadingDois = this.LoadingTres = this.LoadingQuatro = false;
        return 'Cancelado';
        break;

      case '8':
        this.barraUm = this.barraDois = this.barraTres = true;
        this.LoadingUm = this.LoadingDois = this.LoadingTres = false;
        this.barraQuatro = false;
        this.LoadingQuatro = true;
        return 'Entregas Concluidas';
        break;

      default:
        this.barraUm = this.barraDois = this.barraTres = this.barraQuatro = false;
        this.LoadingDois = this.LoadingTres = this.LoadingQuatro = false;
        this.LoadingUm = true;
        return 'Em Andamento';
        break;
    }
  }
}