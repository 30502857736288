import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';

@Component({
  selector: 'app-ver-solicitacao',
  templateUrl: './ver-solicitacao.component.html',
  styleUrls: ['./ver-solicitacao.component.css']
})
export class VerSolicitacaoComponent implements OnInit {
  public entregador;
  public isOnline;
  public rotas;
  public pedido
  ;
 
  public icone_cinza =  {
    url: 'assets/marker-cinza.png',
    scaledSize: {
        width: 30,
        height: 40
    }
};
  public icone_vermelho = {
    url: 'assets/marker.png',
    scaledSize: {
        width: 30,
        height: 40
    }
};
  
  
  constructor(
    private active : ActivatedRoute,
    private _http : RequisicoesService

  ) { 
    this.active.params.subscribe(params => {
      console.log(params);
      
      this.pedido = params

      
      this.rotas = JSON.parse(params.todas_rotas) 
      this.rotas.forEach((rota, index) => {
        console.log("status da rota");
        console.log(rota.status);
        
        if(rota.status ==  "6"){
          console.log("TESTE");
          console.log(this.rotas[index].icone);
          
          this.rotas[index].icone_maker = {
            url: 'assets/localizacao.png',
            scaledSize: {
                width: 30,
                height: 40
            }
        };

        }else{

          this.rotas[index].icone_maker ={
            url: 'assets/marker.png',
            scaledSize: {
                width: 30,
                height: 40
            }
        };

        }

      });
    })

  }

  ngOnInit(): void {
    this.isOnline = localStorage.getItem("shj.isOnline")

    this.entregador = JSON.parse(localStorage.getItem('shj.usuario'));

   
    

    console.log(this.rotas);
    
  }

  favoritar(){
    
  }
}
