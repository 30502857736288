import { Component, OnInit } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { environment } from 'src/environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.css']
})
export class HistoricoComponent implements OnInit {
 public pedidos: any;
 public rotas: any;
 public entregador: any = {};
 public historico = true;
  public isOnline;
  constructor(
    private _http : RequisicoesService,

  ) {
   }

  ngOnInit(): void {
    this.isOnline = localStorage.getItem("shj.isOnline");

    console.log(this.isOnline);
    
    this.entregador = JSON.parse(localStorage.getItem('shj.usuario'));
    this.buscarPedidos();
  }
  buscarPedidos(){
    this._http.get("pedidos", {historico: 1,entregador_id: this.entregador.id, tipo:"entregadores"}).subscribe(
      async (resposta: any) => {

         if (resposta.status == "sucesso") { 
          this.pedidos = resposta.data;
          
      
          console.log(this.pedidos);
        }
      
       
       },
      erro => {
       this.pedidos  = false;
      
      }
      );
  }
}
