import { Component, OnInit } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/environments/environment';
import { UtilService } from 'src/app/services/utils/util.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';
import { MensagemSucessoComponent } from '../../modals/mensagem-sucesso/mensagem-sucesso.component';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-perfil-cliente',
  templateUrl: './perfil-cliente.component.html',
  styleUrls: ['./perfil-cliente.component.css']
})
export class PerfilClienteComponent implements OnInit {
  public cliente:any = {};
  public estados:any = {};
  public emailValido:any ;
  public meuemail;
  public imagemToUpload: File = null;

  public form: any;

  constructor(
    private _http:  RequisicoesService,
    public httpClient: HttpClient,
    private formBuilder : FormBuilder,
    private toastr: ToastrService,
    private utils: UtilService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.cliente = JSON.parse(localStorage.getItem('shj.usuario')); 
    this.getEstado();
    this.meuemail = this.cliente.email;


  }

  async editar(form){
    let empty = await this.utils.checkEmptyFields(this.cliente, ['razao_social','nome_fantasia','cnpj','celular','cep','endereco','numero','bairro','cidade','estado','email']);
    console.log(empty);
    if (empty) {
      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'sm'
      });
      modal.componentInstance.title = "Preencha os campos obrigatórios!";      
      return false;
    }    

    if(this.emailValido == false){

      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'sm'
      });
      modal.componentInstance.title = "Esse email já está sendo utilizado!";      
      return false;
  
    }
    this._http.put("clientes",{"id": this.cliente.id,"cliente":this.cliente}).subscribe(
      async (resposta: any) => {
         if (resposta.status == "sucesso") {
          localStorage.setItem('shj.usuario', JSON.stringify(resposta.data));
          const modal = this.modalService.open(MensagemSucessoComponent, {
            size: 'sm'
          });
          modal.componentInstance.title = "Perfil alterado com sucesso!";
        }
      },
      erro => {
        const modal = this.modalService.open(MensagemErroComponent, {
          size: 'sm'
        });
        modal.componentInstance.title = "Houve um erro ao alterar o perfil, tente novament!";      }
    );
  }

  async cep() {
    console.log("teste")
    var x = this.cliente.cep;
    this._http.get("cep", { 'cep': x })
      .subscribe(
        (resposta: any) => {
          if (resposta.status == 'sucesso') {
            var dados = JSON.parse(resposta.data);
            console.log(dados);
             this.cliente.endereco = dados.success['tp_logradouro'] + " " + dados.success['logradouro'];
             this.cliente.bairro = dados.success['bairro'];
             this.cliente.cidade = dados.success['cidade'];
             this.cliente.estado = dados.success['estado'].toUpperCase();
          } 
          else {}
        },
        (erro) => {
          console.log(erro)
        }
      )
  }

  PesquisarEmail(){
    if(this.cliente.email != "" && this.cliente.email != this.meuemail){
      this._http.get("clientes", {email: this.cliente.email}).subscribe(
        async (resposta: any) => {
          console.log(resposta);
           if (resposta.status == "erro") {
            this.emailValido = true;
          }else{
            this.emailValido = false;
          }
         },
        erro => {
          console.log(erro);
        }
      );
    }
  }

  abrirArquivo() {
    document.getElementById('imagem').click()
  }

  async imageSelectedAvatar(files: FileList) {
    this.imagemToUpload = files.item(0);
    const formData = new FormData();
    formData.append('file', this.imagemToUpload, this.imagemToUpload.name);
    console.log(formData);
    this.httpClient.post(API_URL+'api/uploads', formData).subscribe( async (resposta: any) => {
      if (resposta.status == 'success') {
        console.log(resposta.data);
        this.cliente.avatar = resposta.data;
      }
   });  
  }

  getEstado(){
    this._http.get("estados", []).subscribe(
      async (resposta: any) => {
        console.log(resposta);
        console.log(resposta.data);
         if (resposta.status == "success") {
          this.estados = resposta.data;
          console.log(resposta.data);
        }
       },
      erro => {
        console.log(erro);
      }
    );
  }

}
