import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilService } from 'src/app/services/utils/util.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { MensagemSucessoComponent } from '../../modals/mensagem-sucesso/mensagem-sucesso.component';
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  faleConosco: FormGroup;

  public faleconosco: any = {};
  constructor(
    private formBuilder : FormBuilder,
    private utils : UtilService,
    public http : RequisicoesService,
    private modalService: NgbModal,
    private AlertService : AlertService,

  ) { }

  ngOnInit(): void {

    this.faleConosco = this.formBuilder.group({
      nome: new FormControl(this.faleconosco.nome, Validators.required),
      email: new FormControl(this.faleconosco.email, Validators.required),
      assunto: new FormControl(this.faleconosco.assunto, Validators.required),
      mensagem: new FormControl(this.faleconosco.mensagem, Validators.required),
    });     

  } 
 
  enviar(){
    console.log(this.faleConosco['value']);
    if (this.faleConosco.invalid) {

      Object.keys(this.faleConosco.controls).forEach(control => {
        if (control !== null) {
          if (this.faleConosco.get(control).invalid) {
            console.log("kk");
            if (document.querySelector("#" + control) != undefined) {
              document
                .querySelector("#" + control)
                .classList.add("campo-invalido");
            }
          } else {
            // console.log(control);
            if (
              document
                .querySelector("#" + control)
                .classList.contains("campo-invalido")
            ) {
              document
                .querySelector("#" + control)
                .classList.remove("campo-invalido");
            }
          }

        }

      });
      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'sm'
      });
      modal.componentInstance.title = "Preencha todos os campos";
      return false;
    }
      
      this.http.post("faleconosco", this.faleConosco["value"]).subscribe(
        async (resposta: any) => {
      
           if (resposta.status == "sucesso") {
            const modal = this.modalService.open(MensagemSucessoComponent, {
              size: 'sm'
            });
            modal.componentInstance.title = "Sua mensagem foi enviada com sucesso!";
          }
    
         
         },
        erro => {
   
          const modal = this.modalService.open(MensagemErroComponent, {
            size: 'sm'
          });
          modal.componentInstance.title = "Erro ao enviar mensagem tente novamente!";     
        }
      );
    }
   

  
}
