<div id="inicio">
    <app-header></app-header>
    <div  class="container-fluid">
        <div class="row">
            <div class="col p-0">
                <img class="banner" src="/assets/banner.png">
            </div>
        </div>
    </div>
    <div id="home" class="container">
        <div class="row" id="quem_somos" >
            <div class="col" style="margin-left:55px;">
                <h5><b>QUEM SOMOS</b></h5>
            </div>
        </div>
        <div  class="row">
            <div class="barra col-md-1">
            </div>
            <div class="texto col-md-10">
                <p>Somos uma empresa sólida que atua no mercado de entregas a 14 anos na cidade de São Paulo e Campinas.</p>
                <p>Contamos com profissionais qualificados, treinados e com experiência em transporte,
                    tomando todos os cuidados de higiene para oferecer uma entrega segura e agradável. </p>
                <p> Nosso objetivo é a satisfação do consumidor, tendo como prioridade o comprometimento do serviço prestado e em manter a qualidade.</p>
                <p>Com a visão e meta de expandir cada vez mais os serviços, implementando tecnologia de qualidade e serviço ao um custo justo.</p>
            </div>
        </div>

        <div class="row" id="como_funciona">
            <div class="col-md-12">
                <h5>
                    <p class="centralizar " ><b class="linha">COMO FUNCIONA</b></p>
                </h5>
            </div>
        </div>
        <div class="row" style="margin-top:5px;">
            <div class="col-md-3 col-sm-12" routerLink="/cadastro-cliente" style="cursor: pointer;">
                <div class="row align-items-center">
                    <div class="col-md-12 col-sm-12 p-1">
                      <img class="img-fluid padrao_img" src="/assets/cadastrese.png">
                      <h6 class="text-center"><strong>Cadastre-se</strong></h6>
                    <p class="text-center p-1 texto">Cadastre-se na plataforma <br> via formulário "Fale Conosco" </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <div class="row align-items-center">
                    <div class="col-md-12 col-sm-12 p-1">
                      <img class="img-fluid padrao_img" src="/assets/solicite.png">
                      <h6 class="text-center"><strong>Solicite</strong></h6>
                    <p class="text-center p-1 texto">Após o cadastro, acesse o <br> app e solicite sua entrega</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <div class="row align-items-center">
                    <div class="col-md-12 col-sm-12 p-1">
                      <img class="img-fluid padrao_img" src="/assets/acompanhe.png" >
                      <h6 class="text-center"><strong>Acompanhe</strong></h6>
                    <p class="text-center p-1 texto">Acompanhe o andamento <br> do seu pedido em tempo real </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <div class="row align-items-center">
                    <div class="col-md-12 col-sm-12 p-1">
                      <img class="img-fluid padrao_img" src="/assets/gerencie.png">
                      <h6 class="text-center"><strong>Gerencie</strong></h6>
                    <p class="text-center p-1 texto">Gerencie de forma fácil <br> os serviços prestados</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid">
        <div class="row" id="download_app">
            <div class="col-md-12 p-0 banner-download">
                <div class="col-md-5 mt-8 texto-banner">
                    <img class="aviao-banner" src="assets/aviao.png" />
                    <h2 class="paragrafo_banner">Disponível para <br />Android & IOS</h2>
                    <img src="assets/lojas.png" class="img-fluid" usemap="#map"/>
                    <map name="map">
                        <area alt="Play Store" title="playStore" href="https://play.google.com/store/apps/details?id=shj.alphacode.com.br&hl=pt_BR&gl=US" target="_blank" coords="2,37,202,118" shape="rect">
                        <area alt="App Store" title="AppStore" href="https://apps.apple.com/nz/app/shj-express/id1503354661" target="_blank" coords="211,38,399,117" shape="rect">
                    </map>
                </div>
            </div>
        </div>

        <div id="home" class="container">

            <div class="row" id="vantagens">
                <div class="col-md-12">
                    <h5>
                        <p class=" centralizar "><b class="linha">VANTAGENS</b></p>
                    </h5>

                    <div class="row text-center">
                        <div class="col-md-6 offset-md-3 p-2 sub_vantagens">
                            Conheça as principais vantagens da SHJ Express, frente a concorrência.
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-12">
                        <div class="row align-items-center">
                            <div class="col-md-12 col-sm-12 p-1">
                            <img class="img-fluid padrao_img_vantagens" src="/assets/empresasolida.png">
                            <h6 class="text-center"><strong>Empresa sólida no <br> mercado de delivery</strong></h6>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-sm-12 p-1">
                                <img class="img-fluid padrao_img_vantagens" src="/assets/qualidadeeseguranca.png">
                                <h6 class="text-center"><strong>Qualidade e segurança na <br> prestação do serviço</strong></h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-sm-12 p-1">
                                <img class="img-fluid padrao_img_vantagens" src="/assets/profissionaisdiferenciados.png">
                                <h6 class="text-center"><strong>Profissionais diferenciados <br> para melhor atende-lo</strong></h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-sm-12 p-1">
                                <img class="img-fluid padrao_img_vantagens" src="/assets/fococonsumidor.png">
                                <h6 class="text-center"><strong>Foco no <br> consumidor</strong></h6>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="row" id="fale_conosco" style="margin-bottom:105px;">
            <div class="col-md-12">
                <app-footer></app-footer>

            </div>
        </div>
    </div>

     <app-footer-logado></app-footer-logado>
</div>
