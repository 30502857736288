<app-header [cliente]="cliente" [historico]="true" [logado]="true"></app-header>
<div class="container-fluid container-ba">
    <div class="container-conteudo">
        <div *ngIf="!entregador.avatar" [style.background]="'url(assets/user.png)'" class="avatar" ></div>
        <div *ngIf="entregador.avatar" class="avatar" [style.background]="'url('+ entregador.avatar  + ')'"></div>
            <h6 class="mt-2"><b>{{entregador.nome}}</b></h6>
            <p>{{entregador.marca}} / {{entregador.modelo}}</p>
            <p>Placa: {{entregador.placa}}</p>
            <b *ngIf="acao == 'bloquear'">Deseja realmente bloquear o entregador?</b>
            <b *ngIf="acao == 'avaliar'"> Como deseja avaliar?</b>
            <div *ngIf="acao == 'avaliar'">
                <bar-rating [theme]="'stars'" [(ngModel)]="rate" [max]="5" ></bar-rating>
            </div>
            <textarea class="form-control" placeholder="Digite aqui a descricao..."  [(ngModel)]="descricao"></textarea>
            <div *ngIf="acao == 'bloquear'" class="bloco-botao col-3">
                <app-botao-vermelho  (click)="bloquear()" title="Bloquear"></app-botao-vermelho>
            </div>
            <div *ngIf="acao == 'avaliar'" class="bloco-botao col-3">
                <app-botao-vermelho  (click)="avaliar()" title="Avaliar"></app-botao-vermelho>
            </div>
            <!-- <p *ngIf="tipo == 'historico' && pedido.media">Avaliacao<bar-rating [rate]="pedido.media" [theme]="'stars'" [max]="5" disabled></bar-rating></p> -->
    </div>
</div>
<app-footer-logado></app-footer-logado>