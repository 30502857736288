import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RequisicoesService } from '../services/requisicoes/requisicoes.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MensagemSucessoComponent } from '../modals/mensagem-sucesso/mensagem-sucesso.component';
import { MensagemErroComponent } from '../modals/mensagem-erro/mensagem-erro.component';
import { Router } from '@angular/router';
import { API_URL } from 'src/environments/environment';
import { UtilService } from '../services/utils/util.service';
@Component({
  selector: 'app-cadastro-entregador',
  templateUrl: './cadastro-entregador.component.html',
  styleUrls: ['./cadastro-entregador.component.css']
})
export class CadastroEntregadorComponent implements OnInit {
 public entregador:any = {};
 public senhasIncompativeis;
 public estados: any = {};
 public veiculos: any = {};
 public ambiente: any = {};
public emailValido;
 bool : boolean = false;
 public parametrizacoes:any;
 public horarios_select: any;
 public horarios: any;
 public imagemToUpload: File = null;

 @ViewChild('confirmar_senha')confirmar_senha: ElementRef;

  constructor(
    private toastr: ToastrService,
    private _http:  RequisicoesService,
    public httpClient: HttpClient,
    private modalService: NgbModal,
    private router: Router,
    private utils: UtilService
  ) { }

 async ngOnInit() {
    this.getHorarios();

    this.getEstado();
    this.buscaParamerizacao();
    this.horarios = await this.getHorariosEntregador();


  }
  async getHorariosEntregador(){
    console.log(' caiu aqui')

    return new Promise((resolve, reject) => {
     this._http.get("horarios", {entregador_id:this.entregador.id}).subscribe(
       async (resposta: any) => {

            this.bool = true
            console.log("horarios_entregador");

            console.log(resposta.data);

           resolve(resposta.data)

        },
       erro => {
         console.log(erro);
       }
     );
    })
   }
  async getHorarios(){

    this._http.get("time", []).subscribe(
      async (resposta: any) => {

         if (resposta.status == "sucesso") {
          this.horarios_select = resposta.data;
        }



       },
      erro => {
        console.log(erro);
      }
    );

  }
  PesquisarEmail(){
    if(this.entregador.email != ""){
      this._http.get("entregadores", {email: this.entregador.email}).subscribe(
        async (resposta: any) => {
          console.log(resposta);

           if (resposta.status == "erro") {

            this.emailValido = true;

          }else{

            this.emailValido = false;

          }




         },
        erro => {
          console.log(erro);
        }
        );

    }
  }
  abrirArquivoAvatar(){
    document.getElementById('imagem').click();
  }
  abrirArquivoRg() { document.getElementById('imagem_rg').click(); }
  abrirArquivoCNH() { document.getElementById('imagem_cnh').click(); }
  abrirArquivoDoc() { document.getElementById('imagem_doc').click(); }

   imageSelectedAvt(files: FileList) {

    this.imagemToUpload = files.item(0);


    const formData = new FormData();
    formData.append('file', this.imagemToUpload, this.imagemToUpload.name);

    console.log(formData);

    this.httpClient.post(API_URL+'api/uploads', formData).subscribe( async (resposta: any) => {
         if (resposta.status == 'success') {
           console.log(resposta.data);

           this.entregador.avatar = resposta.data;
       }

   });
  }
  async imageSelectedRg(event) {

    this.imagemToUpload = event.target.files[0];

    const formData = new FormData();
    formData.append('file', this.imagemToUpload, this.imagemToUpload.name);

    console.log(formData);

    this.httpClient.post(API_URL+'api/uploads', formData).subscribe( async (resposta: any) => {
         if (resposta.status == 'success') {
           console.log(resposta.data);

           this.entregador.rg_cpf_img = resposta.data;
       }

   });
 }
   async imageSelectedCnh(files: FileList) {

   this.imagemToUpload = files.item(0);
   const formData = new FormData();
   formData.append('file', this.imagemToUpload, this.imagemToUpload.name);

   console.log(this.imagemToUpload);


   this.httpClient.post(API_URL+'api/uploads', formData).subscribe( async (resposta: any) => {
     if (resposta.status == 'success') {
       console.log(resposta.data);

       this.veiculos.cnh_img = resposta.data;
   }

});
 }

 async imageSelectedDoc(files: FileList) {

   this.imagemToUpload = files.item(0);

   const formData = new FormData();
   formData.append('file', this.imagemToUpload, this.imagemToUpload.name);

   console.log(this.imagemToUpload);


   this.httpClient.post(API_URL+'api/uploads', formData).subscribe( async (resposta: any) => {
     if (resposta.status == 'success') {
       console.log(resposta.data);

       this.veiculos.doc_img = resposta.data;
   }
 });

 }
  buscaParamerizacao(){
    this._http.get("parametrizacao").subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {

          this.parametrizacoes = resposta.data;
          console.log("parametrizacao");

          console.log(this.parametrizacoes);


        }




      },
      erro => {
        console.log(erro);
      }
    );
  }

  getEstado(){

    this._http.get("estados", []).subscribe(
      async (resposta: any) => {
        console.log(resposta);
        console.log(resposta.data);
         if (resposta.status == "success") {
          this.estados = resposta.data;
          console.log(resposta.data);

        }



       },
      erro => {
        console.log(erro);
      }
    );
  }
  verificarSenha(){
    console.log("teste");

    this.confirmar_senha.nativeElement.value
        console.log('confirmar_senha');
        let confirmar_senha = this.confirmar_senha.nativeElement.value;
        console.log(confirmar_senha);

        if(this.entregador.senha != "" && this.entregador.senha != undefined  && confirmar_senha != "" && confirmar_senha != undefined ){
          if(this.entregador.senha != confirmar_senha ){
            this.toastr.error('Senhas incompatíveis!', '');

            this.senhasIncompativeis = true;
          }else{
            this.senhasIncompativeis = false;

          }

        }else{
          this.senhasIncompativeis = false;

        }
  }
  async cep() {
    console.log("teste")
    var x = this.entregador.cep;
    this._http.get("cep", { 'cep': x })
      .subscribe(
        (resposta: any) => {
          if (resposta.status == 'sucesso') {
            var dados = JSON.parse(resposta.data);
            console.log(dados);
             this.entregador.endereco = dados.success['tp_logradouro'] + " " + dados.success['logradouro'];
             this.entregador.bairro = dados.success['bairro'];
             this.entregador.cidade = dados.success['cidade'];
             this.entregador.estado = dados.success['uf'].toUpperCase();


          } else {
          }
        },
        (erro) => {
          console.log(erro)
        }
      )
  }
 async salvar(){
  if(this.emailValido == false){

    const modal = this.modalService.open(MensagemErroComponent, {
      size: 'sm'
    });
    modal.componentInstance.title = "Esse email já está sendo utilizado!";
    return false;

  }


  let empty = await this.utils.checkEmptyFields(this.entregador, ['email','senha','nome', 'cpf', 'rg', 'celular', 'data_nascimento','cep', 'bairro','endereco','numero','cidade','estado','banco','agencia','conta']);

  if (empty) {
    const modal = this.modalService.open(MensagemErroComponent, {
      size: 'sm'
    });
    modal.componentInstance.title = "Preencha os campos obrigatórios!";
    return false;

  }
  let empty_veiculos = await this.utils.checkEmptyFields(this.veiculos, ['tipo_id', 'marca', 'modelo', 'ano', 'placa', 'renavam', 'cnh', 'data_vencimento_cnh', 'data_vencimento_doc']);
  if (empty_veiculos) {
    const modal = this.modalService.open(MensagemErroComponent, {
      size: 'sm'
    });
    modal.componentInstance.title = "Preencha os campos obrigatórios!";
    return false;
  }
  this._http.post("entregadores",{entregadores: this.entregador, horarios: this.horarios, veiculos: this.veiculos}).subscribe(
      async (resposta: any) => {

         if (resposta.status == "sucesso") {

              const modal = this.modalService.open(MensagemSucessoComponent, {
                size: 'sm'
              });
              modal.componentInstance.title = "Cadastro concluído com sucesso!";
              this.router.navigate(['/login'])


        }



       },
      erro => {
        const modal = this.modalService.open(MensagemErroComponent, {
          size: 'sm'
        });
        modal.componentInstance.title = "Houve um erro ao alterar o perfil, tente novament!";      }
    );
 }

}
