<div class="modal-body p-0 width-100 flex">
    <div class="img-modal" ></div>
    <div *ngIf="envia == false" class="p-4 width-100 flex">
        <p>
            <b>Deseja selecionar um<br /> Entregador preferencial?</b><br />
        </p>
        <app-botao-vermelho (click)="selecaoMototista()" title="Sim" class="p-4"></app-botao-vermelho>
        <app-botao-vermelho (click)="solicitar()" title="Não" class="p-4"></app-botao-vermelho>
    </div>
    <div *ngIf="envia == true" class="p-4 width-100 flex">
        <p><b>Sua solicitação foi<br />enviada com sucesso!</b><br /></p>
        <p>Por favor, aguarde a aceitação<br /> do entregador</p><br />
        <app-botao-vermelho (click)="finalizar()" title="Ok" class="p-4"></app-botao-vermelho>
    </div>
</div>