import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { MensagemSucessoComponent } from 'src/app/modals/mensagem-sucesso/mensagem-sucesso.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';import { MensagemErroComponent } from 'src/app/modals/mensagem-erro/mensagem-erro.component';

@Component({
  selector: 'app-pedido-card',
  templateUrl: './pedido-card.component.html',
  styleUrls: ['./pedido-card.component.css']
})
export class PedidoCardComponent implements OnInit {
  @Input() pedidos:any =[];
  public usuario

  constructor(
    private _http : RequisicoesService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem("shj.usuario"));
  }
  aceitar(pedido){
    console.log(pedido);
    console.log("USUARIO ");
    
    console.log(this.usuario);
    
    
    this._http.put("pedidos", {id: pedido.id, status:"Aceito", entregador_id: this.usuario.id}).subscribe(
      async (resposta: any) => {
        console.log(resposta);

         if (resposta.status == "sucesso") { 
          const modal = this.modalService.open(MensagemSucessoComponent,{
            size: 'sm'
          });
          modal.componentInstance.title = "Sua solicitação foi aceita!";
          modal.componentInstance.subtitle = "Por favor, verifique seu aplicativo pra continuar.";

        }else{
          const modal = this.modalService.open(MensagemErroComponent,{
            size: 'sm'
          });
          modal.componentInstance.title = resposta.mensagem;
        }
      
       
       },
      erro => {
        const modal = this.modalService.open(MensagemErroComponent,{
          size: 'sm'
        });
        modal.componentInstance.title = "Houve um erro ao aceitar solicitação, tente novamente!";
      }      
      );

  }

  recusar(pedido){
    console.log(pedido);
    
    this._http.put("pedidos", {id: pedido.id, status:"Recusado",  entregador_id: this.usuario.id}).subscribe(
      async (resposta: any) => {
        console.log(resposta);

         if (resposta.status == "sucesso") { 
          const modal = this.modalService.open(MensagemSucessoComponent,{
            size: 'sm'
          });
          modal.componentInstance.title = "Sua solicitação foi recusada!";

        }else{
          const modal = this.modalService.open(MensagemErroComponent,{
            size: 'sm'
          });
          modal.componentInstance.title = resposta.mensagem;
        }
      
      
       
       },
      erro => {
        const modal = this.modalService.open(MensagemErroComponent,{
          size: 'sm'
        });
        modal.componentInstance.title = "Houve um erro ao recusar solicitação, tente novamente!";
      }      
      );
  }
 }

