import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-mensagem-confirmacao',
  templateUrl: './mensagem-confirmacao.component.html',
  styleUrls: ['./mensagem-confirmacao.component.css']
})
export class MensagemConfirmacaoComponent implements OnInit {
  @Input() titulo : any = '';
  @Input() mensagem : any = '';
  @Input() pedido_id : any = '';

  public confirm : Boolean = false;
 
  constructor(
    private _http : RequisicoesService,
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal, 
    private router:Router,
    public httpClient: HttpClient,
    private toastr: ToastrService,
  ){ }
  ngOnInit(): void {

  }

  close(){
    this.modalService.dismissAll();
    this.router.navigate(['/em-andamento-cliente']);
  }
  sim(){
    this._http.put("pedidos", {pedido_id: this.pedido_id, status: 'Cancelar'}).subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {
          this.confirm = true;
        }
      },
      erro => {
        console.log(erro);
      }
    );
  }
  nao(){
    this.modalService.dismissAll();
  }
}
