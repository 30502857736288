import { Component, OnInit, Input } from '@angular/core';
import { AppRoutingModule } from '../../app-routing.module';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-entregador',
  templateUrl: './home-entregador.component.html',
  styleUrls: ['./home-entregador.component.css']
})
export class HomeEntregadorComponent implements OnInit {
  public entregador:any = {};
  public veiculos:any = {};
  public pedidos:any = [];
  public isOnline:any = Boolean;
  public home:Boolean = true;
  public clt:any;

  constructor( 
    private _http : RequisicoesService,
    private router: Router,

  ) {}
  ionViewDidEnter(){
   this.buscarPedidos();
  }
  ngOnInit(): void {
    this.isOnline = localStorage.getItem("shj.isOnline")
    this.entregador = JSON.parse(localStorage.getItem('shj.usuario'));
    this.veiculos = JSON.parse(localStorage.getItem('shj.veiculos'));
    this.clt = this.entregador.clt;
    console.log(this.entregador);

    this.buscarPedidos();
  }
  buscarPedidos(){
    let tipo_veiculo = '';
    switch(this.veiculos.tipo_id){
      case 1:
        tipo_veiculo = 'Carro';
        break;
      case 2:
        tipo_veiculo = 'Moto';
        break;
      case 3:
        tipo_veiculo =  'Carro utilitario'
        break;
    }
    this._http.get("pedidos", {home_entregador:1,entregador_id: this.entregador.id, status_id: 1, tipo:"entregadores", verifica_bloqueio : 1, tipo_veiculo : tipo_veiculo}).subscribe(
      async (resposta: any) => {
        console.log(resposta);
         if (resposta.status == "sucesso") { 
          this.pedidos = resposta.data;
          console.log(this.pedidos);
        }
        else{
          this.pedidos = false;
        }
       },
      erro => {
       this.pedidos = false;
      }
    );
  }

  MudarStatus() {
    if (this.isOnline != 1) {
      this.isOnline = 1;
      localStorage.setItem("shj.isOnline", this.isOnline);

    } else {
      this.isOnline =0;
      localStorage.setItem("shj.isOnline", this.isOnline);
    }

    console.log(localStorage.getItem("shj.isOnline"));
    
    this._http.put("entregadores", {id: this.entregador.id,  entregadores : {disponibilidade: this.isOnline } }).subscribe(
      async (resposta: any) => {
      }, error => {
        console.log('error')
    });
  }
   
}
