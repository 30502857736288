import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mensagem-sucesso',
  templateUrl: './mensagem-sucesso.component.html',
  styleUrls: ['./mensagem-sucesso.component.css']
})
export class MensagemSucessoComponent implements OnInit {

  @Input() title : String = ''
  @Input() subtitle : String = ''
  @Input() botao : any = 'Ok'

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  
  }

  close(){
    this.modalService.dismissAll()
    location.reload()

  }
}
