import { Injectable } from '@angular/core';
// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  // showModal(titulo: string, mensagem: string){
  //   const bsModalRef: BsModalRef = this.modalService.show(ModalComponent);
  //   bsModalRef.content.titulo = titulo;
  //   bsModalRef.content.mensagem = mensagem;
  // }
}
