<app-header [cliente]="cliente" [home]="true" [logado]="true"></app-header>
<img class="img-home-cliente mb-3" src="../assets/fundo_home_clientes.png" alt="">
<div class="container-fluid container-principal">
    <form [formGroup]="solicitacaoForm">
    <div class="row">
            <div class="col-12">
                <h2>Solicitar</h2>
            </div>
        </div>
        <div class="row pt-3 pb-3">
            <div class="col-3 col-md-6 col-sm-12" *ngIf="veiculos != null">
                <label>Tipo de veículo</label>
                <select placeholder="Escolha um tipo..." (change)="selectTipoVeiculo()" class="form-control input" name="veiculos[id]" formControlName="tipo_veiculo" value="veiculos.id">
                    <option value="" disabled>Selecione...</option>
                    <option value="{{veiculo.id}}" *ngFor="let veiculo of veiculos">
                        {{veiculo.tipo_veiculo}}
                    </option>
                </select>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label>CEP</label>
                <input class="form-control input" type="text" id="cep" formControlName="cep" (blur)="cep()" mask='00000-000' placeholder="CEP">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label>Endereço</label>
                <input class="form-control input" type="text" id="endereco" formControlName="endereco"   placeholder="Digite aqui o endereço...">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label>Numero</label>
                <input class="form-control input"  type="tel" formControlName="numero" placeholder="Número">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label>Complemento</label>
                <input class="form-control input" type="text"  formControlName="complemento" placeholder="Complemento">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 box-btn-gray">
                <button type="button" (click)="adicionar()" class="btn-gray-mid">Adicionar mais endereços</button>
            </div>
        </div>
        <div class="row mt-5">
            <div *ngFor="let endereco of enderecos; let i = index" class="bloco-enderecos col-12">
                <div class="col-lg-6 col-md-8 col-sm-10 box-enderecos">
                    <p>{{endereco.endereco}} - {{endereco.numero}}</p>
                    <p>{{endereco.complemento}}</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2">
                    <img class="btn-fechar" (click)="remover(i)" src="../assets/close.png" alt="">
                </div>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-3 col-md-4 col-sm-12">
                <app-botao-vermelho  (click)="solicitar()" title="Solicitar"></app-botao-vermelho>
            </div>
        </div> 
    </form>
</div>
<app-footer-logado></app-footer-logado>