<div class="modal-body p-0 width-100 flex">
    <div class="img-modal" ></div>
    <div class="p-4 width-100 flex">
        <p><strong>Esqueceu sua senha?</strong></p>
        <p>Digite seu e mail para a recuperação.</p><br />
        
            <form class="width-100 flex" [formGroup]="redefinirForm" >
                <input type="email" class="form-control input" id="email" formControlName="email" placeholder="E-mail"/>
                
                <app-botao-vermelho (click)="enviar()" title="Enviar" class="p-4"></app-botao-vermelho>

            </form>
    </div>
</div>