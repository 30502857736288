<div class="row">
    <div  *ngFor="let pedido of pedidos" class="col-lg-3 col-md-4 col-sm-10 offset-md-0 offset-sm-1 col-xs-12 border-card">
        <div class="row">
            <div class="col-md-12 p-3">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <div *ngIf="pedido.avatar" class="avatar_card" [style.background]="'url('+ pedido.avatar  + ')'">
                        </div>
                        <div *ngIf="!pedido.avatar" [style.background]="'url(assets/user.png)'" class="avatar_card" ></div>
                        <div *ngIf="pedido.favoritado == 1" class="marker-fav" >
                            <svg class="bi bi-heart-fill" color="red" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" clip-rule="evenodd"/>
                            </svg>
                        </div>
                    </div>
                    <div *ngIf="!!pedido.entregador_id" (click)="ver(pedido.id)" class="col-md-8 cursor-pointer col-sm-8 col-xs-8">
                        <h6><b>{{pedido.nome}}</b></h6>
                        <p>{{pedido.marca}} / {{pedido.modelo}}</p>
                        <p>Placa: {{pedido.placa}}</p>
                        <!-- <p *ngIf="tipo == 'historico' && pedido.media">Avaliacao<bar-rating [rate]="pedido.media" [theme]="'stars'" [max]="5" disabled></bar-rating></p> -->
                        <p *ngIf="tipo == 'historico' && pedido.media">Avaliacao: {{pedido.media}} <img style="height: .7rem;" src="../assets/star-1.png" alt=""> </p>
                    </div>
                    <div *ngIf="!pedido.entregador_id" (click)="ver(pedido.id)" class="col-md-8 cursor-pointer col-sm-9 col-xs-8">
                        <h6>Aguardando aceite!</h6>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                    <div *ngIf="tipo == 'em_andamento'" class="col-lg-2 col-md-1 col-sm-1 col-xs-1 display-flex j-fe">
                        <img *ngIf="tipo == 'em_andamento'" class="btn-cancelar" (click)="cancelar(pedido.id)" src="../assets/close.png" alt="">
                    </div>
                    <div *ngIf="tipo == 'historico'" class="col-lg-2 col-md-1 col-sm-1 display-flex fd-c">
                        <img *ngIf="tipo == 'historico' && pedido.favoritado == 0 && pedido.entregador_id" class="btn-cancelar icones-historico" (click)="favoritar(pedido.entregador_id, pedido.cliente_id)" src="../assets/heart2.svg" alt="">
                        <img *ngIf="tipo == 'historico' && pedido.favoritado == 1 && pedido.entregador_id" class="btn-cancelar icones-historico" (click)="desfavoritar(pedido.f_id)" src="../assets/heart.svg" alt="">
                        <img *ngIf="tipo == 'historico'" class="btn-cancelar icones-historico" (click)="bloquear(pedido.entregador_id)" src="../assets/block-2.png" alt="">
                    </div>
                </div>
                <div (click)="ver(pedido.id)" class="row cursor-pointer bloco-enderecos mt-3">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <p>Solicitação: {{pedido.data_solicitacao}}</p>
                        <p *ngIf="tipo == 'historico'" >Finalização:  em: {{pedido.data_finalizacao}}</p>
                        <p>Número de rotas: {{pedido.n_rotas}}</p>
                    </div>
                </div>
                <div (click)="ver(pedido.id)"  class="row cursor-pointer bloco-enderecos mt-3">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <p>Status:</p>
                        <div class="display-flex">
                            <div [style.color]="pedido.color">
                                <svg class="bi bi-circle-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="8" cy="8" r="8"/>
                                </svg>
                            </div>
                            <p class="pl-1 ">{{pedido.nome_status}}</p>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
    </div>
    <div *ngIf="pedidos == false" class="container">
        <span class='nenhum_pedido'>Nenhum pedido no momento.  </span>
    </div>
</div>