import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { HomeClienteComponent } from './cliente/home-cliente/home-cliente.component';
import { HomeEntregadorComponent } from './entregador/home-entregador/home-entregador.component';
import { HistoricoComponent } from './entregador/historico/historico.component';
import { PerfilEntregadorComponent } from './entregador/perfil-entregador/perfil-entregador.component';
import { CadastroClienteComponent } from './cadastro-client/cadastro-cliente.component';
import { CadastroEntregadorComponent } from './cadastro-entregador/cadastro-entregador.component';
import { VerSolicitacaoComponent } from './entregador/ver-solicitacao/ver-solicitacao.component';
import { FavoritosComponent } from './cliente/favoritos/favoritos.component';
import { PerfilClienteComponent } from './cliente/perfil-cliente/perfil-cliente.component';
import { EmAndamentoClienteComponent } from './cliente/em-andamento-cliente/em-andamento-cliente.component';
import { PedidoAndamentoComponent } from './cliente/pedido-andamento/pedido-andamento.component';
import { HistoricoClienteComponent } from './cliente/historico-cliente/historico-cliente.component';
import { FavoritosClienteComponent } from './cliente/favoritos-cliente/favoritos-cliente.component';
import { BloqueioAvaliaçaoComponent } from './cliente/bloqueio-avaliacao/bloqueio-avaliacao.component';

const routes: Routes = [
  { path: '',  redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent},

  { path: '',  redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},

  { path: 'home-cliente',  redirectTo: 'home-cliente', pathMatch: 'full' },
  { path: 'home-cliente', component: HomeClienteComponent}, 
  
  { path: 'favoritos',  redirectTo: 'favoritos', pathMatch: 'full' },
  { path: 'favoritos', component: FavoritosComponent}, 

  { path: 'favoritos-cliente',  redirectTo: 'favoritos-cliente', pathMatch: 'full' },
  { path: 'favoritos-cliente', component: FavoritosClienteComponent}, 
  
  { path: 'em-andamento-cliente',  redirectTo: 'em-andamento-cliente', pathMatch: 'full' },
  { path: 'em-andamento-cliente', component: EmAndamentoClienteComponent},
  
  { path: 'historico-cliente',  redirectTo: 'historico-cliente', pathMatch: 'full' },
  { path: 'historico-cliente', component: HistoricoClienteComponent},
  
  { path: 'pedido-andamento',  redirectTo: 'pedido-andamento', pathMatch: 'full' },
  { path: 'pedido-andamento', component: PedidoAndamentoComponent},
  
  { path: 'bloqueio-avaliacao',  redirectTo: 'bloqueio-avaliacao', pathMatch: 'full' },
  { path: 'bloqueio-avaliacao', component: BloqueioAvaliaçaoComponent},

  { path: 'home-entregador',  redirectTo: 'home-entregador', pathMatch: 'full' },
  { path: 'home-entregador', component: HomeEntregadorComponent},

  { path: 'perfil-entregador',  redirectTo: 'perfil-entregador', pathMatch: 'full' },
  { path: 'perfil-entregador', component: PerfilEntregadorComponent}, 

  { path: 'historico',  redirectTo: 'historico', pathMatch: 'full' },
  { path: 'historico', component: HistoricoComponent},

  { path: 'ver-solicitacao',  redirectTo: 'ver-solicitacao', pathMatch: 'full' },
  { path: 'ver-solicitacao', component: VerSolicitacaoComponent},

  { path: 'cadastro-entregador',  redirectTo: 'cadastro-entregador', pathMatch: 'full' },
  { path: 'cadastro-entregador', component: CadastroEntregadorComponent},

  { path: 'cadastro-cliente',  redirectTo: 'cadastro-cliente', pathMatch: 'full' },
  { path: 'cadastro-cliente', component: CadastroClienteComponent},

  { path: 'perfil-cliente',  redirectTo: 'perfil-cliente', pathMatch: 'full' },
  { path: 'perfil-cliente', component: PerfilClienteComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
