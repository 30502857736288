<app-header [entregador]="entregador" [perfil_entregador]="true" [isOnline]="isOnline" [logado]="true"></app-header>
<div class="pl-5 ml-5 pt-5 mr-5 pr-5">
    <form #form="ngForm" (ngSubmit)="editar(form)">

        <div class="row">

            <div class="col-md-12  mt-4">
                <p><span>Dados pessoais</span></p>
                    <img *ngIf="entregador.avatar" (click)="abrirArquivo()" class="padrao_img mr-3" src="{{entregador.avatar}}"/>
                    <img *ngIf="!entregador.avatar" (click)="abrirArquivo()" class="imgavatar mr-3"  src="/assets/usuario_default.png"/>
                    <input type="file" style="display:none;" name="entregadores[avatar]" [(ngModel)]="entregador.avatar" id="imagem" (change)="imageSelectedAvatar($event.target.files)" class="form-control hide " >

                <span >Faça upload da imagem</span>
            </div>
            <div class="col-md-3 col-lg-3">
                <label>Nome Completo</label>
                <input class="form-control" name="entregadores[nome]" [(ngModel)]="entregador.nome" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3">
                <label>CPF</label>
                <input class="form-control" name="entregadores[cpf]" [(ngModel)]="entregador.cpf" mask="000.000.000-00" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3">
                <label>RG</label>
                <input class="form-control" name="entregadores[rg]" [(ngModel)]="entregador.rg"  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3">
                <label>Celular</label>
                <input class="form-control"  name="entregadores[celular]" [(ngModel)]="entregador.celular"  mask="(00) 00000-0000" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-3">
                <label>Data Nascimento</label>
                <input class="form-control" mask="00/00/0000"  name="entregadores[data_nascimento]" [(ngModel)]="entregador.data_nascimento" placeholder="Digite aqui" />
            </div>
        </div>
        <div class="row mt-4 pt-4" >
            <div class="col-md-12">
                <span>Endereço</span>
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>CEP</label>
                <input class="form-control" (blur)="cep();" mask="00000-000" name="entregadores[cep]" [(ngModel)]="entregador.cep"  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">

                <label>Endereço</label>
                <input class="form-control"     name="entregadores[endereco]" [(ngModel)]="entregador.endereco" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Número</label>
                <input class="form-control"  name="entregadores[numero]" [(ngModel)]="entregador.numero" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Complemento</label>
                <input class="form-control"   name="entregadores[complemento]" [(ngModel)]="entregador.complemento"    placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Bairro</label>
                <input class="form-control"    name="entregadores[bairro]" [(ngModel)]="entregador.bairro"  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Cidade</label>
                <input class="form-control"   name="entregadores[cidade]" [(ngModel)]="entregador.cidade"    placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 col-lg-3 pt-4">
                <label>Estado</label>
                <select class="form-control" placeholder="Selecione um Estado"  name="entregadores[estado]" [(ngModel)]="entregador.estado" >
                    <option value="{{estado}}" *ngFor="let estado of estados"> {{estado}}</option>
                </select>
            </div>
        </div>
        <div class="row mt-4 pt-4">
            <div class="col-md-12">
                <span>Dados Bancários</span>
            </div>
            <div class="col-md-3 mt-4">
                <label>Banco</label>
                <input class="form-control" name="entregadores[banco]" [(ngModel)]="entregador.banco" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 mt-4">
                <label>Agência</label>
                <input class="form-control"  name="entregadores[agencia]" [(ngModel)]="entregador.agencia" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3 mt-4">
                <label>Conta</label>
                <input class="form-control" name="entregadores[conta]" [(ngModel)]="entregador.conta" placeholder="Digite aqui" />
            </div>
        </div> 
        <div class="row mt-4 pt-4">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <p><span>Horários</span></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Segunda-feira</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3"  placeholder="Selecione um Estado" >
                        <select placeholder="De" class="form-control input" name="horarios[segunda_feira][horario_inicial]" [(ngModel)]="horarios.segunda_feira.horario_inicial" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3" placeholder="Selecione um Estado">
                        <select placeholder="De"  class="form-control input" name="horarios[segunda_feira][horario_final]" [(ngModel)]="horarios.segunda_feira.horario_final" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div> 
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <label>Terça-feira</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3"  placeholder="Selecione um Estado" >
                        <select placeholder="De" class="form-control input" name="horarios[terca_feira][horario_inicial]" [(ngModel)]="horarios.terca_feira.horario_inicial" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div>
                
                    <div class="col-md-3">
                        <select placeholder="De"  class="form-control input"  name="horarios[terca_feira][horario_final]" [(ngModel)]="horarios.terca_feira.horario_final" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div> 
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <label>Quarta-feira</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3" >
                        <select placeholder="De" class="form-control input" name="horarios[quarta_feira][horario_inicial]" [(ngModel)]="horarios.quarta_feira.horario_inicial" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div>
                
                    <div class="col-md-3" >
                        <select placeholder="De"  class="form-control input"  name="horarios[quarta_feira][horario_final]" [(ngModel)]="horarios.quarta_feira.horario_final"  >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div> 
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <label>Quinta-feira</label>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-3" >
                        <select placeholder="De" class="form-control input" name="horarios[quinta_feira][horario_inicial]"  [(ngModel)]="horarios.quinta_feira.horario_inicial" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div>
                
                    <div class="col-md-3" placeholder="Selecione um Estado" >
                        <select placeholder="De"  class="form-control input" name="horarios[quinta_feira][horario_final]" [(ngModel)]="horarios.quinta_feira.horario_final" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div> 
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <label>Sexta-feira</label>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3" >
                        <select placeholder="De" class="form-control input" name="horarios[sexta_feira][horario_inicial]" [(ngModel)]="horarios.sexta_feira.horario_inicial" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div>
                
                    <div class="col-md-3" placeholder="Selecione um Estado"  >
                        <select placeholder="De"  class="form-control input" name="horarios[sexta_feira][horario_final]" [(ngModel)]="horarios.sexta_feira.horario_final" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div> 
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <label>Sábado</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <select placeholder="De" class="form-control input" name="horarios[sabado][horario_inicial]" [(ngModel)]="horarios.sabado.horario_inicial"  >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div>
                
                    <div class="col-md-3">
                        <select placeholder="De"  class="form-control input"name="horarios[sabado][horario_final]" [(ngModel)]="horarios.sabado.horario_final" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div> 
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <label>Domingo</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <select placeholder="De" class="form-control input" name="horarios[domingo][horario_inicial]" [(ngModel)]="horarios.domingo.horario_inicial" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div>
                
                    <div class="col-md-3">
                        <select placeholder="De"  class="form-control input" name="horarios[domingo][horario_final]" [(ngModel)]="horarios.domingo.horario_final" >
                            <option value="{{horario_select}}" *ngFor="let horario_select of horarios_select">
                                {{horario_select}}
                            </option>
                        </select>
                    </div>  
                </div>
            </div>
        </div>
         <div class="row mt-4">
            <div class="col-md-12">
                <span>Dados de Acesso</span>
            </div>
            <div class="col-md-3">
                <label>E-mail</label>
                <input class="form-control" (blur)='PesquisarEmail();' name="entregadores[email]" [(ngModel)]="entregador.email" placeholder="Digite aqui" />
                <img *ngIf="!emailValido" class="icone_check" src="/assets/check.png"> 
                <img *ngIf="emailValido" class="icone_check" src="/assets/check2.png">
            </div>
            <div class="col-md-3">
                <label>Senha</label>
                <input class="form-control" name="entregadores[senha]" [(ngModel)]="entregador.senha" type="password" (blur)='verificarSenha();'  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3">
                <label>Confimar sua senha</label>
                <input class="form-control" (blur)='verificarSenha();' #confirmar_senha type="password" placeholder="Digite aqui" />
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <span>Dados de Veículo</span>
            </div>
            <div class="col-md-3">
                <label>Tipo</label>
                <select placeholder="Escolha um tipo"  class="form-control input" name="veiculos[tipo_id]" [(ngModel)]="veiculos.tipo_id" value="veiculos.tipo_id">
                    <option value="{{parametrizacao.id}}" *ngFor="let parametrizacao of parametrizacoes">
                        {{parametrizacao.tipo_veiculo}}
                    </option>
                </select>

            </div>
            <div class="col-md-3">
                <label>Marca</label>
                <input placeholder="Digite aqui" class="form-control" name="veiculos[marca]" [(ngModel)]="veiculos.marca" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3">
                <label>Modelo</label>
                <input placeholder="Digite aqui" class="form-control" name="veiculos[modelo]" [(ngModel)]="veiculos.modelo"  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3">
                <label>Ano</label>
                <input placeholder="Digite aqui" mask="0000" name="veiculos[ano]" [(ngModel)]="veiculos.ano" class="form-control"  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3">
                <label>Placa</label>
                <input placeholder="Digite aqui" class="form-control" name="veiculos[placa]" [(ngModel)]="veiculos.placa"  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3">
                <label>Renavam</label>
                <input placeholder= "Digite aqui" class="form-control" name="veiculos[renavam]" [(ngModel)]="veiculos.renavam"  placeholder="Digite aqui" />
            </div>
            <div class="col-md-3">
                <label>CNH</label>
                <input placeholder="Digite aqui" class="form-control" name="veiculos[cnh]" [(ngModel)]="veiculos.cnh" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3">
                <label>Data de vencimento CNH</label>
                <input placeholder="Digite aqui" class="form-control" mask="00/00/0000" name="veiculos[data_vencimento_cnh]" [(ngModel)]="veiculos.data_vencimento_cnh" placeholder="Digite aqui" />
            </div>
            <div class="col-md-3">
                <label>Data de vencimento(Documento do veículo)</label>
                <input placeholder="Digite aqui" class="form-control" mask="00/00/0000" name="veiculos[data_vencimento_doc]" [(ngModel)]="veiculos.data_vencimento_doc" placeholder="Digite aqui" />
            </div>
          
        </div>
            <div class="row mt-4">
            <div class="col-md-12">
                <span>Uploads</span>
              <div class="row mt-3">
                  <div class="col-md-2">
                      <label>RG/CPF</label>
                  </div>
                  <div class="col-md-2">
                    <label>CNH</label>
                </div>
                <div class="col-md-2">
                    <label>Documento do veículo</label>
                </div>
              </div>
               <div class="row">
                    <div class="col-md-2 ">
                        <img *ngIf="entregador.rg_cpf_img"  (click)="abrirArquivoRg()"  class="imagem_uploads"src="{{entregador.rg_cpf_img}}" />
                        <img *ngIf="!entregador.rg_cpf_img" (click)="abrirArquivoRg()"  class="imagem_uploads" src="/assets/camera_uploads.png" />

                         <input type="file" style="display:none;" name="entregadores[rg_cpf_img]" [(ngModel)]="entregador.rg_cpf_img" id="imagem_rg" (change)="imageSelectedRg($event)" class="form-control hide " >

                    </div> 
                    <div class="col-md-2">
                        <img *ngIf="veiculos.cnh_img" (click)="abrirArquivoCNH()" class="imagem_uploads" src="{{veiculos.cnh_img}}" >
                        <img *ngIf="!veiculos.cnh_img" (click)="abrirArquivoCNH()" class="imagem_uploads" src="/assets/camera_uploads.png" />
                        
                        
                        <input type="file" style="display:none;" name="entregadores[cnh_img]" [(ngModel)]="veiculos.cnh_img" id="imagem_cnh" (change)="imageSelectedCnh($event.target.files)" class="form-control hide " >

                    </div>
                    <div class="col-md-2 ">
                        <img *ngIf="veiculos.doc_img" (click)="abrirArquivoDoc()" class="imagem_uploads" src="{{veiculos.doc_img}}" />
                        <img *ngIf="!veiculos.doc_img" (click)="abrirArquivoDoc()"class="imagem_uploads" src="/assets/camera_uploads.png" />

                        <input type="file" style="display:none;" name="entregadores[doc_img]" [(ngModel)]="veiculos.doc_img" id="imagem_doc" (change)="imageSelectedDoc($event.target.files)" class="form-control hide " >

                    </div>
               </div>
            </div>
            
     
        </div> 
        <div class="row mt-4 mb-4">
            <div class="col-md-12">
                <div class="centralizar">
                   <button class="submit" type="submit"><app-botao-vermelho  title="Editar"></app-botao-vermelho></button>

                </div>
            </div>
        </div> 

  </form>
</div>
<app-footer-logado></app-footer-logado>

