import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rotas-historico',
  templateUrl: './rotas-historico.component.html',
  styleUrls: ['./rotas-historico.component.css']
})
export class RotasHistoricoComponent implements OnInit {
  @Input() rotas
  constructor() { 

  }

  ngOnInit(): void {
    this.rotas = JSON.parse(this.rotas);
    console.log("rotas");
    console.log(this.rotas);
       this.rotas.forEach((rota, index) => {
        console.log("status da rota");
        console.log(rota.status);
        
        if(rota.status ==  "6"){
          console.log("TESTE");
          console.log(this.rotas[index].icone);
          
          this.rotas[index].icone = 'assets/localizacao.png';
         

        }else{

          this.rotas[index].icone = 'assets/marker.png';

        }

      });
    
  }

}
