import { Component, OnInit,ElementRef, ViewChild  } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask'
import { ComponentsModule } from 'src/app/components.module';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MensagemSucessoComponent } from '../../modals/mensagem-sucesso/mensagem-sucesso.component';
import { MensagemErroComponent } from '../../modals/mensagem-erro/mensagem-erro.component';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/environments/environment';
import { UtilService } from 'src/app/services/utils/util.service';



@Component({
  selector: 'app-perfil-entregador',
  templateUrl: './perfil-entregador.component.html',
  styleUrls: ['./perfil-entregador.component.css']
})
export class PerfilEntregadorComponent implements OnInit {
  public entregador:any = {};
  public logado:any;
  public veiculos:any = {};
  public ambiente:any;
  public form: any;
  bool : boolean = false;
  
  public horarios: any = {}; 
  public senhasIncompativeis;
  public imagemToUpload: File = null;
  @ViewChild('confirmar_senha')confirmar_senha: ElementRef;

  formPerfil: FormGroup;
  public horarios_select = [];
  public parametrizacoes:any = [];
  public estados =  {};
  public emailValido:any ;
  public meuemail;
  public isOnline;
  constructor(
    private _http:  RequisicoesService,
    public httpClient: HttpClient,
    private formBuilder : FormBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private utils: UtilService


  ) { }

  async ngOnInit(){
    this.isOnline = localStorage.getItem("shj.isOnline")

    this.entregador = JSON.parse(localStorage.getItem('shj.usuario'));
    this.meuemail = this.entregador.email;

    this.veiculos = JSON.parse(localStorage.getItem('shj.veiculos'));
    // this.buscaEntregador();

    
    
    this.getHorarios();
    this.getEstado();
    this.buscaParamerizacao(); 

    this.horarios = await this.getHorariosEntregador();
    console.log("segunda feira(horarios) ");
    
    console.log(this.horarios);


  }
  buscaEntregador(){

    this._http.get("entregadores",{id: this.entregador.id}).subscribe(
      async (resposta: any) => {
    
         if (resposta.status == "sucesso") {
  
               console.log(resposta);
               
            //   localStorage.setItem('shj.usuario',resposta.data);

            // this.meuemail = this.entregador.email;
          
            // this.entregador = JSON.parse(localStorage.getItem('shj.usuario'));

        }

      
       
       },
      erro => {
       }
    );
  }
  PesquisarEmail(){
    if(this.entregador.email != "" && this.entregador.email != this.meuemail){
      this._http.get("entregadores", {email: this.entregador.email}).subscribe(
        async (resposta: any) => {
          console.log(resposta);
  
           if (resposta.status == "erro") {
        
            this.emailValido = true;
            
  
          }else{

            this.emailValido = false;

          }
  
  
        
         
         },
        erro => {
          console.log(erro);
        }
        );

    }
  }
  async cep() {
    console.log("teste")
    var x = this.entregador.cep;
    this._http.get("cep", { 'cep': x })
      .subscribe(
        (resposta: any) => {
          if (resposta.status == 'sucesso') {
            var dados = JSON.parse(resposta.data);
            console.log(dados);
             this.entregador.endereco = dados.success['tp_logradouro'] + " " + dados.success['logradouro'];
             this.entregador.bairro = dados.success['bairro'];
             this.entregador.cidade = dados.success['cidade'];
             this.entregador.estado = dados.success['uf'].toUpperCase();


   

          } else {
          }
        },
        (erro) => {
          console.log(erro)
        }
      )
  }
 async  editar(form){

    let empty = await this.utils.checkEmptyFields(this.entregador, ['email','senha','nome', 'cpf', 'rg', 'celular', 'data_nascimento','cep', 'bairro','endereco','numero','cidade','estado','banco','agencia','conta']);
    if (empty) {
      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'sm'
      });
      modal.componentInstance.title = "Preencha os campos obrigatórios!";      
      return false;
  
    }     

    let empty_veiculos = await this.utils.checkEmptyFields(this.veiculos, ['tipo_id', 'marca', 'modelo', 'ano', 'placa', 'renavam', 'cnh', 'data_vencimento_cnh', 'data_vencimento_doc']);
    if (empty_veiculos) {
      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'sm'
      });
      modal.componentInstance.title = "Preencha os campos obrigatórios!";      
      return false;
    } 
    
    if(this.emailValido == false){

      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'sm'
      });
      modal.componentInstance.title = "Esse email já está sendo utilizado!";      
      return false;
  
    }
    form.form.value.id = this.entregador.id;


    this._http.put("entregadores",{id: this.entregador.id, entregadores: this.entregador, horarios: this.horarios, veiculos: this.veiculos}).subscribe(
      async (resposta: any) => {
    
         if (resposta.status == "sucesso") {
  

              localStorage.setItem('shj.usuario', JSON.stringify(resposta.data.entregador));
              localStorage.setItem('shj.veiculos', JSON.stringify(resposta.data.veiculos));

              const modal = this.modalService.open(MensagemSucessoComponent, {
                size: 'sm'
              });
              modal.componentInstance.title = "Perfil alterado com sucesso!";
          
        }

      
       
       },
      erro => {
        const modal = this.modalService.open(MensagemErroComponent, {
          size: 'sm'
        });
        modal.componentInstance.title = "Houve um erro ao alterar o perfil, tente novament!";      }
    );
  }

  abrirArquivo() { document.getElementById('imagem').click(); }
  abrirArquivoRg() { document.getElementById('imagem_rg').click(); }
  abrirArquivoCNH() { document.getElementById('imagem_cnh').click(); }
  abrirArquivoDoc() { document.getElementById('imagem_doc').click(); }

  async imageSelectedAvatar(files: FileList) {
    
    this.imagemToUpload = files.item(0);

  
    const formData = new FormData();
    formData.append('file', this.imagemToUpload, this.imagemToUpload.name);

    console.log(formData);
     
    this.httpClient.post(API_URL+'api/uploads', formData).subscribe( async (resposta: any) => {
         if (resposta.status == 'success') {
           console.log(resposta.data);
           
           this.entregador.avatar = resposta.data;
       }
         
   });  
  }
    async imageSelectedRg(event) {
      
     this.imagemToUpload = event.target.files[0];

     const formData = new FormData();
     formData.append('file', this.imagemToUpload, this.imagemToUpload.name);

     console.log(formData);
      
     this.httpClient.post(API_URL+'api/uploads', formData).subscribe( async (resposta: any) => {
          if (resposta.status == 'success') {
            console.log(resposta.data);
            
            this.entregador.rg_cpf_img = resposta.data;
        }
          
    });    
  }
    async imageSelectedCnh(files: FileList) {
    
    this.imagemToUpload = files.item(0);
    const formData = new FormData();
    formData.append('file', this.imagemToUpload, this.imagemToUpload.name);

    console.log(this.imagemToUpload);
    

    this.httpClient.post(API_URL+'api/uploads', formData).subscribe( async (resposta: any) => {
      if (resposta.status == 'success') {
        console.log(resposta.data);
        
        this.veiculos.cnh_img = resposta.data;
    }
      
});    
  }

  async imageSelectedDoc(files: FileList) {
    
    this.imagemToUpload = files.item(0);

    const formData = new FormData();
    formData.append('file', this.imagemToUpload, this.imagemToUpload.name);

    console.log(this.imagemToUpload);
    

    this.httpClient.post(API_URL+'/api/uploads', formData).subscribe( async (resposta: any) => {
      if (resposta.status == 'success') {
        console.log(resposta.data);
        
        this.veiculos.doc_img = resposta.data;
    }
  });
  
  }
  verificarSenha(){
    console.log("teste");
  
this.confirmar_senha.nativeElement.value
    console.log('confirmar_senha');
    let confirmar_senha = this.confirmar_senha.nativeElement.value;
    console.log(confirmar_senha);
    
    if(this.entregador.senha != "" && this.entregador.senha != undefined  && confirmar_senha != "" && confirmar_senha != undefined ){
      if(this.entregador.senha != confirmar_senha ){
        this.toastr.error('Senhas incompatíveis!', '');

        this.senhasIncompativeis = true;
      }else{
        this.senhasIncompativeis = false;

      }

    }else{
      this.senhasIncompativeis = false;

    }
  }
  buscaParamerizacao(){
        this._http.get("parametrizacao").subscribe(
          async (resposta: any) => {
            if (resposta.status == "sucesso") {
              
              this.parametrizacoes = resposta.data;
              console.log("parametrizacao");
              
              console.log(this.parametrizacoes);
              
              
            }

          
          
          
          },
          erro => {
            console.log(erro);
          }
        );
  }
  async getHorariosEntregador(){
    console.log(' caiu aqui')
 
    return new Promise((resolve, reject) => {
     this._http.get("horarios", {entregador_id:this.entregador.id}).subscribe(
       async (resposta: any) => {
 
            this.bool = true
            console.log("horarios_entregador");

            console.log(resposta.data);
            
           resolve(resposta.data) 
         
        },
       erro => {
         console.log(erro);
       }
     ); 
    })
   }
 
  async getHorarios(){

    this._http.get("time", []).subscribe(
      async (resposta: any) => {

         if (resposta.status == "sucesso") {
          this.horarios_select = resposta.data;
        } 

      
       
       },
      erro => {
        console.log(erro);
      }
    ); 

  }
  getEstado(){

    this._http.get("estados", []).subscribe(
      async (resposta: any) => {
        console.log(resposta);
        console.log(resposta.data);
         if (resposta.status == "success") {
          this.estados = resposta.data;
          console.log(resposta.data);

        }

      
       
       },
      erro => {
        console.log(erro);
      }
    );
  }

    

 
}
