import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { MensagemSucessoComponent } from 'src/app/modals/mensagem-sucesso/mensagem-sucesso.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MensagemErroComponent } from 'src/app/modals/mensagem-erro/mensagem-erro.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pedido-cliente-card',
  templateUrl: './pedido-cliente-card.component.html',
  styleUrls: ['./pedido-cliente-card.component.css']
})
export class ClientePedidoCardComponent implements OnInit {
  @Input() pedidos:any;
  @Input() tipo:any = 'historico';
  public cliente : any = {};

  constructor(
    private _http : RequisicoesService,
    private modalService: NgbModal,
    private router : Router
    ) { }
    
  ngOnInit(): void {
    this.cliente = JSON.parse(localStorage.getItem('shj.usuario'));
    localStorage.removeItem('shj.pedido_id');
    console.log(this.tipo);
    console.log(this.pedidos.media);
    
  }
  cancelar(id){
    this._http.put("pedidos", {pedido_id: id, status: 'Cancelar'}).subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {
          location.reload();
        }
      },
      erro => {
        console.log(erro);
      }
    );
  }

  ver(id){
    localStorage.setItem('shj.pedido_id', id);
    localStorage.setItem('shj.tipo', this.tipo);
    this.router.navigate(['/pedido-andamento']);
  }

  favoritar(e_id, c_id){
    this._http.post("favoritos", {entregador_id : e_id, cliente_id : c_id }).subscribe(
      async (resposta: any) => {
        console.log(resposta);
        if(resposta.status == "sucesso"){
        }
        location.reload();
      },
      erro => {
        console.log(erro);
      }
      );  
    }
    
    desfavoritar(id){
      this._http.delete("favoritos", {id : id}).subscribe(
        async (resposta: any) => {
          console.log(resposta);
          if(resposta.status == "sucesso") {
          }
          location.reload();
      },
      erro => {
        console.log(erro);
      }
    );
  }
  
  bloquear(id){
    this._http.get("bloqueios", { id_entregador : id, id_cliente: this.cliente.id }).subscribe(
      async (resposta: any) => {
        if (resposta.status == "naoencontrado") {
          localStorage.setItem('shj.entregador_id', id);
          localStorage.setItem('shj.acao', 'bloquear');
          this.router.navigate(['/bloqueio-avaliacao']);
        }
        else{
          const modal = this.modalService.open(MensagemErroComponent, {
            size: 'md '
          });
          modal.componentInstance.title = "Prestador já bloqueado!";      
          modal.componentInstance.reload = false;  
        }
      },
      erro => {
        console.log(erro);
      }
    );
  }
}

