<app-header [entregador]="entregador" [historico_entregador]="true" [isOnline]="isOnline" [logado]="true"></app-header>
<div style="margin-left:3%;">
    <div class="row">
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-9 col-sm-9 col-xs-9 card">
                    <p class="mt-3"><b>Endereço de origem: </b></p>
                    <p>{{pedido.endereco_cliente}}, {{pedido.numero_cliente}}</p>
                    <p>{{pedido.complemento_cliente}} </p>
                    <p>{{pedido.bairro_cliente}} - {{pedido.cidade_cliente}} - {{pedido.estado_cliente}}</p>
                    <p>Quilometragem: {{pedido.quilometragem}}Km</p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="endereco_entrega"><b>Endereços de Entrega:</b></p>
                    <app-rotas-historico [rotas]="pedido.todas_rotas"> </app-rotas-historico>
         
                </div>
            </div>
        </div>
        <div class="col-md-6 mt-5 pt-2">
            <agm-map [latitude]="-6.64607562172573" [zoom]="5" [longitude]="-55.54687499999999">
             <agm-marker  [iconUrl]="icone_cinza" [latitude]="pedido.latitude_cliente" [longitude]="pedido.longitude_cliente">
                <agm-info-window [disableAutoPan]="false" #infoWindow>

                    <div>
                        <p  class="rota">Endereço de origem:</p>
                       <p class="rota">{{pedido.endereco_cliente}}, {{pedido.numero_cliente}}</p> 
                       <p class="rota">{{pedido.bairro_cliente}} - {{pedido.cidade_cliente}} </p>
                    </div>
              
                </agm-info-window>

             </agm-marker>
             <agm-marker *ngFor="let rota of rotas" [iconUrl]="rota.icone_maker" [latitude]="rota.latitude" [longitude]="rota.longitude">
                <agm-info-window [disableAutoPan]="false" #infoWindow>

                    <div>
                       <p class="rota">{{rota.endereco}}, {{rota.numero}}</p> 
                       <p class="rota">{{rota.bairro}} - {{rota.cidade}} </p>
                    </div>
              
                </agm-info-window>

             </agm-marker> 

            </agm-map> 
        </div>
    </div>

</div>