import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from "@angular/common";
import { HeaderComponent } from './components/header/header.component';
import { ElementoTextoImgComponent } from './components/elemento-texto-img/elemento-texto-img.component';
import { FooterComponent } from './components/footer/footer.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MensagemSucessoComponent } from './modals/mensagem-sucesso/mensagem-sucesso.component';
import { BotaoVermelhoComponent } from './components/botao-vermelho/botao-vermelho.component';
import { MensagemFaleconoscoComponent } from './modals/mensagem-faleconosco/mensagem-faleconosco.component';
import { MensagemErroComponent } from './modals/mensagem-erro/mensagem-erro.component';
import { PedidoCardComponent } from './components/pedido-card/pedido-card.component';
import { ClientePedidoCardComponent } from './components/pedido-cliente-card/pedido-cliente-card.component';
import { HistoricoCardComponent } from './components/historico-card/historico-card.component';
import { EsqueciSenhaComponent } from './modals/esqueci-senha/esqueci-senha.component';
import { ConfirmacaoPedidoComponent } from './modals/confirmacao-pedido/confirmacao-pedido.component';
import { ExibirValorClienteComponent } from './modals/exibir-valor-cliente/exibir-valor-cliente.component';

import { RotasHistoricoComponent } from './components/rotas-historico/rotas-historico.component';
import { MensagemConfirmacaoComponent } from './modals/mensagem-confirmacao/mensagem-confirmacao.component';
import { FooterLogadoComponent } from './components/footer-logado/footer-logado.component';


@NgModule({
  declarations: [
    HeaderComponent,
    ElementoTextoImgComponent,
    FooterComponent,
    MensagemSucessoComponent,
    BotaoVermelhoComponent,
    MensagemFaleconoscoComponent,
    MensagemErroComponent,
    PedidoCardComponent,
    HistoricoCardComponent,
    EsqueciSenhaComponent,
    ConfirmacaoPedidoComponent,
    ExibirValorClienteComponent,
    RotasHistoricoComponent,
    ClientePedidoCardComponent,
    MensagemConfirmacaoComponent,
    FooterLogadoComponent
  ],
  entryComponents: [MensagemSucessoComponent,MensagemFaleconoscoComponent, MensagemErroComponent, EsqueciSenhaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    EsqueciSenhaComponent, 
    HeaderComponent, 
    BotaoVermelhoComponent, 
    ElementoTextoImgComponent, 
    FooterComponent,
    ClientePedidoCardComponent, 
    PedidoCardComponent, 
    HistoricoCardComponent,
    ConfirmacaoPedidoComponent,
    ExibirValorClienteComponent, 
    RotasHistoricoComponent,
    MensagemConfirmacaoComponent,
    FooterLogadoComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class ComponentsModule { 
  
}
