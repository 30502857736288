<div class="modal-body p-0 width-100 flex">
    <div class="img-modal" ></div>
    <div *ngIf="confirm == false" class="p-4 width-100 flex">
        <p>
            <b>Deseja realmente cancelar o pedido?</b><br />
            {{mensagem}}
        </p>
        <app-botao-vermelho (click)="sim()" title="Sim" class="p-4"></app-botao-vermelho>
        <app-botao-vermelho (click)="nao()" title="Não" class="p-4"></app-botao-vermelho>
    </div>
    <div *ngIf="confirm == true" class="p-4 width-100 flex">
        <p><b>Pedido cancelado com sucesso</b><br /></p>
        <app-botao-vermelho (click)="close()" title="Ok" class="p-4"></app-botao-vermelho>
    </div>
</div>