import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mensagem-faleconosco',
  templateUrl: './mensagem-faleconosco.component.html',
  styleUrls: ['./mensagem-faleconosco.component.css']
})
export class MensagemFaleconoscoComponent implements OnInit {
  @Input() mensagem: string;
  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  close(){
    this.modalService.dismissAll()
  }
}
