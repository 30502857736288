import { Component, OnInit, Input } from '@angular/core';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/services/utils/util.service';

@Component({
  selector: 'app-em-andamento-cliente',
  templateUrl: './em-andamento-cliente.component.html',
  styleUrls: ['./em-andamento-cliente.component.css']
})
export class EmAndamentoClienteComponent implements OnInit {
  public cliente: any = {};
  public pedidos: any;
  public tipo: any = 'em_andamento';

  constructor(
    private _http : RequisicoesService,
    public httpClient: HttpClient,
    private modalService: NgbModal,
    private utils: UtilService,
    private router : Router
  ) {}

  ngOnInit(): void {
    console.log("testando");
    
    this.cliente = JSON.parse(localStorage.getItem('shj.usuario')); 
    this.buscaPedidos();
  }
  buscaPedidos(){
    this._http.get("pedidos", { em_andamento: 1, cliente_id: this.cliente.id }).subscribe(
      async (resposta: any) => {
        if (resposta.status == "sucesso") {
          this.pedidos = resposta.data;
          console.log(this.pedidos)
        }
        else{
          this.pedidos = false;
        }
      },
      erro => {
        console.log(erro);
      }
    );
  }
}