import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RequisicoesService } from '../services/requisicoes/requisicoes.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MensagemSucessoComponent } from '../modals/mensagem-sucesso/mensagem-sucesso.component';
import { MensagemErroComponent } from '../modals/mensagem-erro/mensagem-erro.component';
import { Router } from '@angular/router';
import { API_URL } from 'src/environments/environment';
import { UtilService } from '../services/utils/util.service';
@Component({
  selector: 'app-cadastro-cliente',
  templateUrl: './cadastro-cliente.component.html',
  styleUrls: ['./cadastro-cliente.component.css']
})
export class CadastroClienteComponent implements OnInit {
  public cliente: any = {};
  public senhasIncompativeis;
  public estados: any = {};
  public emailValido;
  bool: boolean = false;
  public imagemToUpload: File = null;

  @ViewChild('confirmar_senha') confirmar_senha: ElementRef;

  constructor(
    private toastr: ToastrService,
    private _http: RequisicoesService,
    public httpClient: HttpClient,
    private modalService: NgbModal,
    private router: Router,
    private utils: UtilService
  ) { }

  async ngOnInit() {

    this.getEstado();
  }

  PesquisarEmail() {
    if (this.cliente.email != "") {
      this._http.get("clientes", { email: this.cliente.email }).subscribe(
        async (resposta: any) => {
          console.log(resposta);
          if (resposta.status == "erro") {

            this.emailValido = true;

          } else {

            this.emailValido = false;

          }
        },
        erro => {
          console.log(erro);
        }
      );

    }
  }

  abrirArquivoAvatar() {
    document.getElementById('imagem').click();
  }

  imageSelectedAvt(files: FileList) {

    this.imagemToUpload = files.item(0);


    const formData = new FormData();
    console.log(formData);
    formData.append('file', this.imagemToUpload, this.imagemToUpload.name);

    console.log(formData);

    this.httpClient.post(API_URL + 'api/uploads', formData).subscribe(async (resposta: any) => {
      if (resposta.status == 'success') {
        console.log(resposta.data);

        this.cliente.avatar = resposta.data;
      }

    });
  }

  getEstado() {
    console.log('aqui');
    this._http.get("estados", []).subscribe(
      async (resposta: any) => {
        console.log(resposta);
        console.log(resposta.data);
        if (resposta.status == "success") {
          this.estados = resposta.data;
          console.log(resposta.data);

        }



      },
      erro => {
        console.log(erro);
      }
    );
  }

  verificarSenha() {
    console.log("teste");

    this.confirmar_senha.nativeElement.value
    console.log('confirmar_senha');
    let confirmar_senha = this.confirmar_senha.nativeElement.value;
    console.log(confirmar_senha);

    if (this.cliente.senha != "" && this.cliente.senha != undefined && confirmar_senha != "" && confirmar_senha != undefined) {
      if (this.cliente.senha != confirmar_senha) {
        this.toastr.error('Senhas incompatíveis!', '');

        this.senhasIncompativeis = true;
      } else {
        this.senhasIncompativeis = false;

      }

    } else {
      this.senhasIncompativeis = false;

    }
  }

  async cep() {
    console.log("teste")
    var x = this.cliente.cep;
    this._http.get("cep", { 'cep': x })
      .subscribe(
        (resposta: any) => {
          if (resposta.status == 'sucesso') {
            var dados = JSON.parse(resposta.data);
            console.log(dados);
            this.cliente.endereco = dados.success['tp_logradouro'] + " " + dados.success['logradouro'];
            this.cliente.bairro = dados.success['bairro'];
            this.cliente.cidade = dados.success['cidade'];
            this.cliente.estado = dados.success['uf'].toUpperCase();


          } else {
          }
        },
        (erro) => {
          console.log(erro)
        }
      )
  }

  async salvar() {
    if (this.emailValido == false) {

      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'sm'
      });
      modal.componentInstance.title = "Esse email já está sendo utilizado!";
      return false;

    }


    let empty = await this.utils.checkEmptyFields(this.cliente, ['email', 'senha', 'razao_social', 'nome_fantasia', 'cnpj', 'celular', 'cep', 'bairro', 'endereco', 'numero', 'cidade', 'estado']);

    if (empty) {
      const modal = this.modalService.open(MensagemErroComponent, {
        size: 'sm'
      });
      modal.componentInstance.title = "Preencha os campos obrigatórios!";
      return false;

    }

    this._http.post("clientes", { clientes: this.cliente }).subscribe(
      async (resposta: any) => {

        if (resposta.status == "sucesso") {

          const modal = this.modalService.open(MensagemSucessoComponent, {
            size: 'sm'
          });
          modal.componentInstance.title = "Cadastro concluído com sucesso!";
          this.router.navigate(['/login'])


        }



      },
      erro => {
        const modal = this.modalService.open(MensagemErroComponent, {
          size: 'sm'
        });
        modal.componentInstance.title = "Houve um erro ao alterar o perfil, tente novament!";
      }
    );
  }

}
