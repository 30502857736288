import { Component, OnInit, Input, Inject } from '@angular/core';
import { AppRoutingModule } from '../../app-routing.module';
import { Router } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { RequisicoesService } from 'src/app/services/requisicoes/requisicoes.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() entregador:any;
  @Input() cliente:any;
  @Input() logado:any = false;
  @Input() home:Boolean  ;
  @Input() historico:Boolean  ;
  @Input() perfil:Boolean ; 
  @Input() andamento:Boolean;
  @Input() favoritos:Boolean;
  @Input() isOnline;
  @Input() clt;
  @Input() historico_entregador:Boolean  ;
  @Input() home_entregador:Boolean  ;
  @Input() perfil_entregador:Boolean  ;




  isNavbarCollapsed=false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private _http : RequisicoesService,
    @Inject(DOCUMENT) private document: any,
    private  pageScrollService : PageScrollService
  ) { }

  ngOnInit(): void {
    // if(localStorage.getItem("shj.localHomeSite") != ""){
    //   console.log(localStorage.getItem("shj.localHomeSite"));

    //   this.scrollToElement(localStorage.getItem("shj.localHomeSite"));
    // }
    if(!this.entregador && !this.cliente){
      this.logado =  false;
    }
  }

  logout(){
    
    
    localStorage.clear();


    this.router.navigate(['/login'])


  }
  verificarClt(clt){
    if(clt == "Sim " || clt == "Sim"){
      this.toastr.error("Entregadore clt's não tem acesso ao histórico!", '');
    }else{
      this.router.navigate(['/historico'])

    }
  }

  scrollToElement(element) : void{
   localStorage.setItem("shj.localHomeSite", element);

      if(element == "#login"){
        this.router.navigate(['/login']);

      }else if(this.router.url == "/home"){
        document.querySelector(element).scrollIntoView();

      }else{
        this.router.navigate(['/home']);

      }
  
  }

}
