<div  class="container">
      <h5 ><p><b class="linha">FALE CONOSCO</b><b></b></p></h5>
</div>
<div class="row" style="margin-top: 2vh;">
    <div class="col-md-2">

    </div>
    <div class="col-md-3">
        <h5>
            <p>Contato</p>
        </h5>
        <p><img class="icone" src="assets/marker.png">Rua Jandiatuba 630, Conjunto 208 Bloco A, Vila Andrade CEP:05716-150</p>
        <p><img class="icone" src="assets/call-answer.png">(11) 2615-1595</p>
        <p><img class="icone" src="assets/envelope.png">shjexpress@uol.com.br</p>

    </div>
    <div class="col-md-5">
         <form [formGroup]="faleConosco" >
             <div class="row">
                 <div class="col-md-12">
                    <input class="form-control input" formControlName="nome" id="nome" placeholder="Nome*"/>
                 </div>
             </div>
             <div class="row">
                 <div class="col-md-12">
                    <input class="form-control input" formControlName="email" id="email" name="email" placeholder="E-mail*"/>
                 </div>
             </div>
             <div class="row">
                 <div class="col-md-12">
                    <input class="form-control input" formControlName="assunto" id="assunto" name="assunto" placeholder="Assunto*"/>
                 </div>
             </div>
             <div class="row">
                 <div class="col-md-12">
                    <textarea class="form-control input" formControlName="mensagem" id="mensagem" name="mensagem" placeholder="Mensagem*"></textarea>
                 </div>
             </div>
                 <div class="col-md-12">
                    <button  class="btn btn-md btn-red" (click)="enviar()">Enviar</button>
                 </div>

        </form> 
    </div>
    <div class="col-md-1">

    </div>
</div>
<!-- <div class="row">
    <div class="col-md-12">
        <div class="container">
            <p class="rodape">@Copyright 2019 | Desenvolvido por: <img src="assets/logoalphacode.png"/></p>
        </div>
    </div>
</div> -->