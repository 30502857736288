<div *ngFor="let rota of rotas" class="col-md-9 col-sm-9 col-xs-9 card">
    <div class="row">
        <div class="col-md-2 mt-3">
            <img class="img_maker ml-2 mt-2" src="{{rota.icone}}"/>
        </div>
        <div class="col-md-7 mt-3 ">
            <p>{{rota.endereco}}, {{rota.numero}}</p>
            <p>{{rota.bairro}} - {{rota.cidade}}</p>
            <p>CEP: {{rota.CEP}}</p>

        </div>
    </div>
</div>