<app-header [entregador]="entregador" [home_entregador]="true"[clt]="clt" [isOnline]="isOnline" [logado]="true"></app-header>
<div class="container-fluid">
    <div class="toggle-button"></div>
    <div class="row">
        <div class="col-md-11 mt-4 pt-2">
            <div class="espaco_switch">
                <img (click)="MudarStatus()" *ngIf="isOnline != 1" class="imgswitch mr-3"  src="/assets/Switch-off.png"/>
                <img (click)="MudarStatus()" *ngIf="isOnline == 1" class="imgswitch mr-3"  src="/assets/Switch.png"/>

                <label class="label_status">{{ isOnline != 1 ? 'Offiline' : 'Online' }}</label>
    
            </div>
         
        </div>
    </div>
    <div class="row" style="padding-bottom: 15vh;">
        <div class="col-md-12 d-flex justify-content-center">
            <h2 *ngIf="isOnline != 1">Entregador Off-line</h2>
            <app-pedido-card *ngIf="isOnline == 1" [home]="home" [pedidos]="pedidos"></app-pedido-card>
        </div>
    </div>
</div>
<app-footer-logado></app-footer-logado>
